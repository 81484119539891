import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  CssBaseline,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import Footer from "../../components/footer/Footer";
import AppHeadLine from "../../components/headLine/AppHeadLine";
import TitleLineComponent from "../../components/titleLine/TitleLineComponent";
import { TITLE_PAGE_LEARNING_WORDS } from "../../constants/titlesConstants";
import { PieChart } from "@mui/x-charts/PieChart";
import { countPage, elementsForPage } from "../../helpers/paginateHelper";
import PaginationComponent from "../../components/pagination/PaginationComponent";
import AlbumIcon from "@mui/icons-material/Album";
import userLearningWordsApi from "../../api/userLearningWords/userLearningWordsApi";
import { useSelector } from "react-redux";
import userLearningWordsSelectors from "../../redux/userLearningWords/userLearningWordsSelectors";
import Loader from "../../components/loader/Loader";
import learningWordStat from "../../models/learningWordStat";
import { clearUserLearningWordsDispatch } from "../../redux/userLearningWords/userLearningWordsDispatcher";
import {
  BORDER_COLOR_WORD_TYPE_ONE,
  BORDER_COLOR_WORD_TYPE_THREE,
  BORDER_COLOR_WORD_TYPE_TWO,
  COLOR_WORD_TYPE_ONE,
  COLOR_WORD_TYPE_THREE,
  COLOR_WORD_TYPE_TWO,
} from "../../constants/appConstants";
import {
  bgColorByWordType,
  colorByWordType,
} from "../../helpers/typeWordHelper";
import appSelectors from "../../redux/app/appSelectors";

export default function LearningWordsPage() {
  const elementsOnPage = 25;
  const [page, setPage] = useState(1);
  const userLearningWordsLoading = useSelector(
    userLearningWordsSelectors.userLearningWordsLoading,
  );
  const learningWords = useSelector(userLearningWordsSelectors.learningWords);
  const completeLearningWordChooseTrue = useSelector(
    appSelectors.completeLearningWordChooseTrue,
  );

  const [loading, setLoading] = useState(true);
  const [showType, setShowType] = useState(-1);
  const [words, setWords] = useState<learningWordStat[]>([]);

  const [countAllLearn, setCountAllLearn] = useState(0);
  const [countTypeOne, setCountTypeOne] = useState(0);
  const [countTypeTwo, setCountTypeTwo] = useState(0);
  const [countTypeThree, setCountTypeThree] = useState(0);
  const [countChooseTypeOne, setChooseCountTypeOne] = useState(0);
  const [countChooseTypeTwo, setChooseCountTypeTwo] = useState(0);

  useEffect(() => {
    userLearningWordsApi();

    return () => {
      clearUserLearningWordsDispatch();
    };
  }, []);

  useEffect(() => {
    let countTypeOneT = 0;
    let countTypeTwoT = 0;
    let countTypeThreeT = 0;
    let countChooseTypeOneT = 0;
    let countChooseTypeTwoT = 0;

    learningWords.map((item: learningWordStat) => {
      if (item.type === 0) {
        countTypeOneT++;
      }
      if (item.type === 1) {
        countTypeTwoT++;
      }
      if (item.type === 2) {
        countTypeThreeT++;
      }

      if (
        item.choose_true === null ||
        item.choose_true <= completeLearningWordChooseTrue
      ) {
        countChooseTypeOneT++;
      }
      if (
        item.choose_true !== null &&
        item.choose_true > completeLearningWordChooseTrue
      ) {
        countChooseTypeTwoT++;
      }
    });

    setCountAllLearn(learningWords.length);
    setCountTypeOne(countTypeOneT);
    setCountTypeTwo(countTypeTwoT);
    setCountTypeThree(countTypeThreeT);
    setChooseCountTypeOne(countChooseTypeOneT);
    setChooseCountTypeTwo(countChooseTypeTwoT);

    setLoading(false);

    changeShowTypeWords(-1);
  }, [learningWords]);

  function changeShowTypeWords(type: number) {
    setShowType(type);
    setWords(
      sortByChooseTrue(
        learningWords.filter((value: learningWordStat) => {
          return type === -1 ? true : value.type === type;
        }),
      ),
    );
    setPage(1);
  }

  function sortByChooseTrue(array: learningWordStat[]) {
    try {
      return array.sort((a: learningWordStat, b: learningWordStat) => {
        if (a.choose_true > b.choose_true) {
          return 1;
        }
        if (a.choose_true < b.choose_true) {
          return -1;
        }

        return 0;
      });
    } catch (exception) {
      return array;
    }
  }

  function colorProgress(count: number | null) {
    if (count === null || count <= completeLearningWordChooseTrue) {
      return { color: "rgb(235, 231, 12)" };
    }

    if (count > completeLearningWordChooseTrue) {
      return { color: "rgb(49, 204, 49)" };
    }
  }

  function textProgress(count: number) {
    if (count <= completeLearningWordChooseTrue) {
      return "Изучаемое слово";
    }

    if (count > completeLearningWordChooseTrue) {
      return "Изученное слово";
    }
  }

  function TypeProgress() {
    return (
      <>
        <Box sx={{ mt: 1, mb: 3 }}>
          <Box sx={{ textAlign: "center", mb: 2 }}>
            <Paper
              elevation={3}
              sx={{
                width: 120,
                height: 120,
                borderRadius: 150,
                pt: 3,
                margin: "0 auto",
                backgroundColor: "#fff",
                border: "2px rgb(0, 1, 255) solid",
                color: "rgb(0, 1, 255)",
                textAlign: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                changeShowTypeWords(-1);
              }}
            >
              <Box sx={{ fontWeight: "bold", fontSize: 25 }}>
                {countAllLearn}
              </Box>
              <Box sx={{ fontSize: 14 }}>{"Всего изучено слов "}</Box>
            </Paper>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Paper
              elevation={12}
              sx={{
                width: 90,
                height: 90,
                borderRadius: 150,
                pt: 3,
                margin: "0 auto",
                backgroundColor: bgColorByWordType(0),
                border: "2px " + BORDER_COLOR_WORD_TYPE_ONE + " solid",
                color: COLOR_WORD_TYPE_ONE,
                textAlign: "center",
                m: 2,
                cursor: "pointer",
              }}
              onClick={() => {
                changeShowTypeWords(0);
              }}
            >
              <Box sx={{ fontWeight: "bold", fontSize: 16 }}>
                {countTypeOne}
              </Box>
              <Box sx={{ fontSize: 12 }}>{"Обычные"}</Box>
            </Paper>
            <Paper
              elevation={12}
              sx={{
                width: 90,
                height: 90,
                borderRadius: 150,
                pt: 3,
                margin: "0 auto",
                backgroundColor: bgColorByWordType(1),
                border: "2px " + BORDER_COLOR_WORD_TYPE_TWO + " solid",
                color: COLOR_WORD_TYPE_TWO,
                textAlign: "center",
                m: 2,
                cursor: "pointer",
              }}
              onClick={() => {
                changeShowTypeWords(1);
              }}
            >
              <Box sx={{ fontWeight: "bold", fontSize: 16 }}>
                {countTypeTwo}
              </Box>
              <Box sx={{ fontSize: 12 }}>{"Редкие"}</Box>
            </Paper>
            <Paper
              elevation={12}
              sx={{
                width: 90,
                height: 90,
                borderRadius: 150,
                pt: 3,
                margin: "0 auto",
                backgroundColor: bgColorByWordType(2),
                border: "2px " + BORDER_COLOR_WORD_TYPE_THREE + " solid",
                color: COLOR_WORD_TYPE_THREE,
                textAlign: "center",
                m: 2,
                cursor: "pointer",
              }}
              onClick={() => {
                changeShowTypeWords(2);
              }}
            >
              <Box sx={{ fontWeight: "bold", fontSize: 16 }}>
                {countTypeThree}
              </Box>
              <Box sx={{ fontSize: 12 }}>{"Эпические"}</Box>
            </Paper>
          </Box>
          <Box sx={{ mt: 3 }}>
            <TableContainer component={Paper}>
              <Table size="small" aria-label="Изученные слова">
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{ fontWeight: "bold", width: "33%" }}
                      align="center"
                    >
                      {"Слово"}
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }} align="center">
                      {"Выбрано правильно"}
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold", width: "33%" }}
                      align="center"
                    >
                      {"Группа"}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {elementsForPage({
                    array: words,
                    pageSize: elementsOnPage,
                    page: page,
                  }).map((row: learningWordStat, index: number) => (
                    <TableRow
                      key={index}
                      sx={{
                        backgroundColor: bgColorByWordType(row.type),
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ color: colorByWordType(row.type) }}
                        align="center"
                      >
                        {row.word}
                      </TableCell>
                      <TableCell align="center">{row.choose_true}</TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          ...colorProgress(row.choose_true),
                          fontWeight: "bold",
                        }}
                      >
                        <Tooltip title={textProgress(row.choose_true)}>
                          <AlbumIcon />
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {elementsForPage({
              array: words,
              pageSize: elementsOnPage,
              page: page,
            }).length === 0 ? (
              <>
                <Box
                  sx={{ textAlign: "center", p: 1, color: "rgba(0,0,0,0.6)" }}
                >
                  {"Пусто"}
                </Box>
              </>
            ) : (
              ""
            )}
            {elementsOnPage < words.length ? (
              <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
                <PaginationComponent
                  count={countPage(words.length, elementsOnPage)}
                  page={page}
                  handleChange={(newPage: number) => {
                    setPage(newPage);
                  }}
                />
              </Box>
            ) : (
              ""
            )}
          </Box>
        </Box>
      </>
    );
  }

  function TypeLayout() {
    return (
      <>
        <Box sx={{ mt: 10 }}>
          <Divider textAlign="left" variant="fullWidth" sx={{ mb: 3 }} />
          <PieChart
            series={[
              {
                data: [
                  {
                    value: countChooseTypeOne,
                    color: "rgb(235, 231, 12)",
                    label: "Изучаются (" + countChooseTypeOne + ")",
                  },
                  {
                    value: countChooseTypeTwo,
                    color: "rgb(49, 204, 49)",
                    label: "Изученные (" + countChooseTypeTwo + ")",
                  },
                ],
                innerRadius: 30,
                outerRadius: 100,
                paddingAngle: 5,
                cornerRadius: 5,
                startAngle: 45,
                endAngle: -225,
                cx: 100,
                cy: 100,
              },
            ]}
            height={210}
            width={330}
          />
        </Box>
        <Box sx={{ mt: 3 }}>
          <Box sx={{ fontSize: 16, fontWeight: "bold", mb: 2 }}>
            {"Группы изученных слов"}
          </Box>
          <Box sx={{ mb: 1 }}>
            <Box sx={{ fontSize: 14 }}>{"Изучаются"}</Box>
            <Box sx={{ fontSize: 12, color: "rgba(0, 0, 0, 0.6)" }}>
              {"Слова, правильное значение которых было выбрано менее " +
                completeLearningWordChooseTrue +
                " раз."}
            </Box>
          </Box>
          <Box sx={{ mb: 1 }}>
            <Box sx={{ fontSize: 14 }}>{"Изученныe"}</Box>
            <Box sx={{ fontSize: 12, color: "rgba(0, 0, 0, 0.6)" }}>
              {"Слова, правильное значение которых было выбрано более " +
                completeLearningWordChooseTrue +
                " раз."}
            </Box>
          </Box>
        </Box>
      </>
    );
  }

  return (
    <>
      <CssBaseline />
      <AppHeadLine />
      <Container maxWidth="md">
        <TitleLineComponent title={TITLE_PAGE_LEARNING_WORDS} />
      </Container>
      <Container maxWidth="sm">
        {userLearningWordsLoading === true || loading === true ? (
          <Loader />
        ) : (
          <>
            <TypeProgress />
            <TypeLayout />
          </>
        )}
      </Container>
      <Footer />
    </>
  );
}
