import React from "react";
import img21 from "../../../assets/img/education/2_1.jpg";
import EducationStepBase from "./EducationStepBase";

export default function EducationStep2() {
  return (
    <EducationStepBase
      title={"Как это работает?"}
      text={
        "На экране появляется слово. Вам нужно выбрать правильное его значение. Всего 4 варианта. Правильное только одно."
      }
      image={img21}
    />
  );
}
