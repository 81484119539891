import React from "react";
import img219 from "../../../assets/img/education/2_19.jpg";
import EducationStepBase from "./EducationStepBase";

export default function EducationStep20() {
  return (
    <EducationStepBase
      title={"Мои словари"}
      text={"Не забудьте сохранить добавленные слова."}
      image={img219}
    />
  );
}
