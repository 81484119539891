import axios, { responseInterface } from "../axios";
import store from "../../redux/store";
import { setTemporaryToken } from "../../redux/app/appReduce";
import { API_USER_TOKEN } from "../../constants/apiRoutConstants";
import userTokenResponse from "./userTokenResponse";
import {
  temporaryTokenLoadingFalseDispatch,
  temporaryTokenLoadingTrueDispatch,
} from "../../redux/app/appDispatcher";
import { alertAppError } from "../../constants/alertConstants";
import { alertWarningDispatch } from "../../redux/alert/alertDispatcher";

export default function userTokenApi() {
  startRequest();

  axios
    .get(API_USER_TOKEN)
    .then((res: any) => {
      successful(res);
    })
    .catch(() => {
      unsuccessful();
    });
}

const startRequest = () => {
  temporaryTokenLoadingTrueDispatch();
};

const successful = (res: responseInterface<userTokenResponse>) => {
  store.dispatch(setTemporaryToken(res.data.token));
  temporaryTokenLoadingFalseDispatch();
};

const unsuccessful = () => {
  alertWarningDispatch(alertAppError);
  temporaryTokenLoadingFalseDispatch();
};
