import React from "react";
import img25 from "../../../assets/img/education/2_5.jpg";
import EducationStepBase from "./EducationStepBase";

export default function EducationStep6() {
  return (
    <EducationStepBase
      title={"Как это работает?"}
      text={"Можно выбрать вариант озвучивания."}
      image={img25}
    />
  );
}
