import store from "../store";
import {
  clearLearnKnowWord,
  setLearnKnowWordLoadingFalse,
  setLearnKnowWordLoadingTrue,
  setLearnKnowWordComplete,
} from "./learnKnowWordReduce";

export function clearLearnKnowWordDispatch() {
  store.dispatch(clearLearnKnowWord());
}

export function setLearnKnowWordLoadingTrueDispatch() {
  store.dispatch(setLearnKnowWordLoadingTrue());
}

export function setLearnKnowWordLoadingFalseDispatch() {
  store.dispatch(setLearnKnowWordLoadingFalse());
}

export function setLearnKnowWordCompleteDispatch(value: boolean | null) {
  store.dispatch(setLearnKnowWordComplete(value));
}
