import axios, { responseInterface } from "../axios";
import { API_AUTH_RESET_PASSWORD } from "../../constants/apiRoutConstants";
import authResetPasswordRequest from "./authResetPasswordRequest";
import { successApiResponseHandle } from "../../services/formErrors/formErrorsService";
import { alertAppError } from "../../constants/alertConstants";
import { alertWarningDispatch } from "../../redux/alert/alertDispatcher";
import {
  setAuthResetPasswordCompleteDispatch,
  setAuthResetPasswordLoadingDispatch,
} from "../../redux/authResetPassword/authResetPasswordDispatcher";

export const authResetPasswordApi = (data: authResetPasswordRequest) => {
  startRequest();
  axios
    .post(API_AUTH_RESET_PASSWORD, data)
    .then((res: any) => {
      successful(res);
    })
    .catch((res: responseInterface) => {
      unsuccessful();
    });
};

const startRequest = () => {
  setAuthResetPasswordCompleteDispatch(null);
  setAuthResetPasswordLoadingDispatch(true);
};

export const successful = (response: responseInterface<any>) => {
  const stopLoadingHandler = () => {
    setAuthResetPasswordLoadingDispatch(false);
  };
  const handler = () => {
    setAuthResetPasswordCompleteDispatch(true);
  };

  successApiResponseHandle(response, stopLoadingHandler, handler);
};

export const unsuccessful = () => {
  setAuthResetPasswordCompleteDispatch(false);
  alertWarningDispatch(alertAppError);
};
