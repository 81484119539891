import React, { useEffect, useState } from "react";
// import { vkAuthService } from "../../../services/vkAuthService/vkAuthService";
import { authSocial, authSocialData } from "../../../api/authSocial";
import { useSelector } from "react-redux";
import { storeInterface } from "../../../redux/store";
import userTokenApi from "../../../api/userToken/userTokenApi";
import Loader from "../../loader/Loader";
import appSelectors from "../../../redux/app/appSelectors";
import { LoadingButton } from "@mui/lab";
import { setTokenLoadingFalseDispatch } from "../../../redux/app/appDispatcher";
import NewWindow from "react-new-window";
import { isApp } from "../../../helpers/appHelper";
import config from "../../../config/config";

export default function VkAuthButton() {
  const [openWindow, setOpenWindow] = useState(false);
  const temporaryToken = useSelector(
    (state: storeInterface) => state.app.temporaryToken,
  );
  const temporaryTokenLoading = useSelector(
    (state: storeInterface) => state.app.temporaryTokenLoading,
  );
  // const tokenLoading = useSelector(appSelectors.tokenLoading);
  const tokenComplete = useSelector(appSelectors.tokenComplete);

  const redirectUrl = (token: string) => {
    return (
      String(config.baseUrl) +
      String(isApp() ? config.vkRedirectUrlApp : config.vkRedirectUrl) +
      token
    );
  };

  const vkAuthUrl = String(config.vkAuthUrl);
  const url = (redirect: string) => {
    return (
      vkAuthUrl +
      "?client_id=" +
      config.vkApiId +
      "&display=popup&redirect_uri=" +
      redirect +
      "&response_type=code"
    );
  };

  useEffect(() => {
    if (temporaryToken.length === 0) {
      userTokenApi();
    }

    return () => {
      setTokenLoadingFalseDispatch();
    };
  }, []);

  useEffect(() => {
    if (tokenComplete === false) {
      setTimeout(() => {
        checkAuth();
      }, 2000);
    }
  }, [tokenComplete]);

  // function afterHandle() {
  //   checkAuth();
  // }

  function checkAuth() {
    const data = authSocialData(temporaryToken);
    authSocial(data);
  }

  function NewWindowLayout() {
    return (
      <NewWindow
        url={url(redirectUrl(temporaryToken))}
        center="parent"
        onOpen={() => {
          setOpenWindow(false);
        }}
        copyStyles={true}
      ></NewWindow>
    );
  }

  return (
    <>
      {temporaryTokenLoading === false ? (
        <>
          <LoadingButton
            loading={false} //tokenLoading
            variant="contained"
            onClick={() => {
              setOpenWindow(true);
              checkAuth();
              // vkAuthService(afterHandle, temporaryToken);
            }}
            sx={{ width: "100%" }}
          >
            {"Через VK"}
          </LoadingButton>
          {openWindow ? <NewWindowLayout /> : ""}
        </>
      ) : (
        <Loader />
      )}
    </>
  );
}
