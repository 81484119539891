import { storeInterface } from "../store";

const userSendEmailConfirmCodeLoading = (state: storeInterface) =>
  state.userSendEmailConfirmCode.userSendEmailConfirmCodeLoading;
const userSendEmailConfirmCodeComplete = (state: storeInterface) =>
  state.userSendEmailConfirmCode.userSendEmailConfirmCodeComplete;

export default {
  userSendEmailConfirmCodeLoading,
  userSendEmailConfirmCodeComplete,
};
