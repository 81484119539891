import React from "react";
import img216 from "../../../assets/img/education/2_16.jpg";
import EducationStepBase from "./EducationStepBase";

export default function EducationStep17() {
  return (
    <EducationStepBase
      title={"Мои словари"}
      text={
        'Cловарь можно заполнить из текста. Для этого нажмите на кнопку "Заполнить из текста".'
      }
      image={img216}
    />
  );
}
