import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import EmailInput from "../../components/forms/inputs/EmailInput";
import { clearFormError } from "../../services/formErrors/formErrorsService";
import { useSelector } from "react-redux";
import userDataSelectors from "../../redux/userData/userDataSelectors";
import { LoadingButton } from "@mui/lab";
import { resetPasswordHandle } from "../../services/resetPassword/resetPasswordService";
import { alertSuccessDispatch } from "../../redux/alert/alertDispatcher";
import { useNavigate } from "react-router-dom";
import { ROUTE_SIGN_IN } from "../../constants/routesConstants";
import { resetPasswordLoadingFalseDispatch } from "../../redux/userData/userDataDispatcher";
import authResetPasswordSelectors from "../../redux/authResetPassword/authResetPasswordSelectors";
import { clearAuthResetPasswordDispatch } from "../../redux/authResetPassword/authResetPasswordDispatcher";

export default function ResetPasswordContainer() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const authResetPasswordLoading = useSelector(
    authResetPasswordSelectors.authResetPasswordLoading,
  );
  const authResetPasswordComplete = useSelector(
    authResetPasswordSelectors.authResetPasswordComplete,
  );
  const sendResetPasswordSuccess = useSelector(
    userDataSelectors.sendResetPasswordSuccess,
  );

  useEffect(() => {
    clearFormError();

    return () => {
      clearAuthResetPasswordDispatch();
    };
  }, []);

  useEffect(() => {
    if (authResetPasswordComplete === true) {
      alertSuccessDispatch("Новый пароль был отправлен на указанный Email.");
      setTimeout(() => {
        navigate(ROUTE_SIGN_IN);
      }, 200);
    }
  }, [authResetPasswordComplete]);

  function changeEmailHandle(email: string) {
    setEmail(email);
  }

  function handle() {
    resetPasswordHandle(email);
  }

  return (
    <>
      <Box sx={{ pb: "25px" }}>
        {
          "Введите Email-адрес, который вы указывали при регистрации. На него будте отправлена инструкция для восстановлению доступа."
        }
      </Box>
      <Box>
        <Grid container>
          <Grid
            xs={2}
            item
            sx={{
              placeSelf: "top",
              pt: "15px",
              pr: "10px",
              textAlign: "right",
              fontWeight: "bold",
              fontSize: "14px",
            }}
          >
            {"Email:"}
          </Grid>
          <Grid xs={8} item>
            <EmailInput
              email={email}
              loading={authResetPasswordLoading}
              valueChangeHandle={changeEmailHandle}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ textAlign: "center" }}>
        <LoadingButton
          loading={authResetPasswordLoading}
          variant="contained"
          onClick={handle}
        >
          {"Отправить"}
        </LoadingButton>
      </Box>
    </>
  );
}
