import React from "react";
import img29 from "../../../assets/img/education/2_9.jpg";
import EducationStepBase from "./EducationStepBase";

export default function EducationStep10() {
  return (
    <EducationStepBase
      title={"Как это работает?"}
      text={
        'или "эпическим". Вероятность появления эпических слов куда ниже остальных.'
      }
      image={img29}
    />
  );
}
