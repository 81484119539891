export const ROUTE_HOME = "/";
export const ROUTE_SIGN_IN = "/signIn";
export const ROUTE_SIGN_UP = "/signUp";
export const ROUTE_PRIVACY = "/privacy";
export const ROUTE_AGREEMENT = "/agreement";
export const ROUTE_CONTACTS = "/contacts";
export const ROUTE_CONFIRM_EMAIL = "/confirmEmail";
export const ROUTE_SERVICES_AND_PAYMENT = "/servicesAndPayment";
export const ROUTE_GROUPS = "/groups";
export const ROUTE_WORKOUT = "/workout";
export const ROUTE_GROUPS_TAB_VALUE = "/groups/:tabValue";
export const ROUTE_GROUPS_ENVIRONMENT = "/groups/environment/:environmentId";
export const ROUTE_GROUPS_ENVIRONMENT_NAVIGATE = "/groups/environment/";
export const ROUTE_GROUPS_TAB_VALUE_NAVIGATE = "/groups/";
export const ROUTE_GROUPS_PERSONAL = "/groupsPersonal";
export const ROUTE_GROUPS_AVAILABLE = "/groups/available";
export const ROUTE_GROUP_PAY = "/group/pay/:groupId";
export const NAVIGATE_GROUP_PAY = "/group/pay/";
export const NAVIGATE_ROUTE_WORDBOOK = "/wordbook/";
export const ROUTE_RESET_PASSWORD = "/resetPassword";
export const ROUTE_LEARN_GROUP = "/learn/:learningWordGroupId";
export const ROUTE_LEARN_GROUP_NAVIGATE = "/learn/";
export const ROUTE_LEARN_TEST_GROUP = "/learn/test/:learningWordGroupTestId";
export const ROUTE_LEARN_TEST_GROUP_NAVIGATE = "/learn/test/";
export const ROUTE_LEARN_EXAM_GROUP = "/learn/:learningWordGroupId/exam";
export const ROUTE_LEARN_EXAM_GROUP_NAVIGATE_ONE = "/learn/";
export const ROUTE_LEARN_EXAM_GROUP_NAVIGATE_TWO = "/exam/";
export const ROUTE_LEARN = "/learn";
export const ROUTE_LEARN_GROUP_PERSONAL_NAVIGATE = "/learn/groupPersonal/";
export const ROUTE_LEARN_GROUP_PERSONAL =
  ROUTE_LEARN_GROUP_PERSONAL_NAVIGATE + ":learningWordGroupPersonalId";
export const ROUTE_LEARN_NEXT_WORD = "/learn/nextWord";
export const ROUTE_PAY = "/pay";
export const ROUTE_SETTINGS = "/settings";
export const ROUTE_BALANCE = "/balance";
export const ROUTE_LEARNING_WORDS = "/learningWords";
export const ROUTE_GROUP_ADD = "/group/add";
export const ROUTE_GROUP_PERSONAL_ADD = "/groupPersonal/add";
export const ROUTE_GROUP_PERSONAL_EDIT_NAVIGATE = "/groupPersonal/edit/";
export const ROUTE_GROUP_PERSONAL_EDIT =
  ROUTE_GROUP_PERSONAL_EDIT_NAVIGATE + ":learningWordGroupPersonalId";
export const ROUTE_GROUP_ADD_FROM_TEXT = "/group/add/fromText";
export const ROUTE_GROUP_ADD_FROM_WORDS = "/group/add/fromWords";
export const ROUTE_GROUP_EDIT = "/group/edit/:groupId";
export const ROUTE_GROUP_EDIT_NAVIGATE = "/group/edit/";
export const ROUTE_GROUP_EDIT_TESTS = "/group/edit/:groupId/tests";
export const ROUTE_GROUP_EDIT_TEST = "/group/edit/:groupId/test/:testId";
export const ROUTE_GROUP_TEST_ADD = "/group/edit/:groupId/test/add";
export const ROUTE_GROUP_TEST_ADD_FROM_WORDS =
  "/group/edit/:groupId/test/add/fromWords";
export const ROUTE_GROUP_TEST_ADD_FROM_TEXT =
  "/group/edit/:groupId/test/add/fromText";
export const ROUTE_GROUP_ADD_EXAM = "/group/edit/:groupId/addExam";
export const ROUTE_GROUP_EXAM_EDIT = "/group/edit/:groupId/editExam";
export const ROUTE_PROFILE = "/profile";
export const ROUTE_FILL_USER_DATA = "/fillUserData";
export const ROUTE_SUPPORT = "/support";
export const ROUTE_USER_UNSUBSCRIBE = "/user/unsubscribe/:email";

export const routeGroupPayNavigate: (groupId: string) => string = (
  groupId: string,
) => {
  return NAVIGATE_GROUP_PAY + groupId;
};

export const routeLearnGroupNavigate: (groupId: string) => string = (
  groupId: string,
) => {
  return ROUTE_LEARN_GROUP_NAVIGATE + groupId;
};

export const routeLearnTestGroupNavigate: (testId: string) => string = (
  testId: string,
) => {
  return ROUTE_LEARN_TEST_GROUP_NAVIGATE + testId;
};

export const routeLearnExamGroupNavigate: (groupId: string) => string = (
  groupId: string,
) => {
  return (
    ROUTE_LEARN_EXAM_GROUP_NAVIGATE_ONE +
    groupId +
    ROUTE_LEARN_EXAM_GROUP_NAVIGATE_TWO
  );
};

export const routeGroupTestsNavigate: (groupId: string) => string = (
  groupId: string,
) => {
  return "/group/edit/" + groupId + "/tests/";
};

export const routEditTestNavigate: (
  groupId: string,
  testId: string,
) => string = (groupId: string, testId: string) => {
  return "/group/edit/" + groupId + "/test/" + testId;
};

export const routeGroupTestAddNavigate: (groupId: string) => string = (
  groupId: string,
) => {
  return "/group/edit/" + groupId + "/test/add";
};

export const routeGroupTestAddFromWordsNavigate: (groupId: string) => string = (
  groupId: string,
) => {
  return "/group/edit/" + groupId + "/test/add/fromWords";
};

export const routeGroupTestAddFromTextNavigate: (groupId: string) => string = (
  groupId: string,
) => {
  return "/group/edit/" + groupId + "/test/add/fromText";
};

export const routeGroupAddExamNavigate: (groupId: string) => string = (
  groupId: string,
) => {
  return "/group/edit/" + groupId + "/addExam";
};

export const routeGroupEditExamNavigate: (groupId: string) => string = (
  groupId: string,
) => {
  return "/group/edit/" + groupId + "/editExam";
};

export const routTestingNavigate: (testId: string) => string = (
  testId: string,
) => {
  return "/testing/" + testId;
};

export const routeGroupsEnvironmentNavigate: (
  environmentId: string,
) => string = (environmentId: string) => {
  return ROUTE_GROUPS_ENVIRONMENT_NAVIGATE + environmentId;
};

export const routeGroupsPersonalEditNavigate: (
  learningWordGroupPersonalId: string,
) => string = (learningWordGroupPersonalId: string) => {
  return ROUTE_GROUP_PERSONAL_EDIT_NAVIGATE + learningWordGroupPersonalId;
};

export const routeLearnGroupPersonalNavigate: (
  learningWordGroupPersonalId: string,
) => string = (learningWordGroupPersonalId: string) => {
  return ROUTE_LEARN_GROUP_PERSONAL_NAVIGATE + learningWordGroupPersonalId;
};
