import React from "react";
import img217 from "../../../assets/img/education/2_17.jpg";
import EducationStepBase from "./EducationStepBase";

export default function EducationStep18() {
  return (
    <EducationStepBase
      title={"Мои словари"}
      text={
        'В появившееся поле вставьте текст, на основании которого вы хотите составить словарь, и нажмите "Заполнить из текста"'
      }
      image={img217}
    />
  );
}
