import { createSlice } from "@reduxjs/toolkit";

export interface userSendEmailConfirmCodeStoreInterface {
  userSendEmailConfirmCodeLoading: boolean;
  userSendEmailConfirmCodeComplete: boolean | null;
}

export const initState: userSendEmailConfirmCodeStoreInterface = {
  userSendEmailConfirmCodeLoading: false,
  userSendEmailConfirmCodeComplete: null,
};

const userSendEmailConfirmCodeReduceSlice = createSlice({
  name: "userSendEmailConfirmCodeReduce",
  initialState: initState,
  reducers: {
    clearUserSendEmailConfirmCode: (
      state: userSendEmailConfirmCodeStoreInterface,
    ) => {
      state.userSendEmailConfirmCodeLoading =
        initState.userSendEmailConfirmCodeLoading;
      state.userSendEmailConfirmCodeComplete =
        initState.userSendEmailConfirmCodeComplete;
    },
    setUserSendEmailConfirmCodeLoadingTrue: (
      state: userSendEmailConfirmCodeStoreInterface,
    ) => {
      state.userSendEmailConfirmCodeLoading = true;
    },
    setUserSendEmailConfirmCodeLoadingFalse: (
      state: userSendEmailConfirmCodeStoreInterface,
    ) => {
      state.userSendEmailConfirmCodeLoading = false;
    },
    setUserSendEmailConfirmCodeCompleteTrue: (
      state: userSendEmailConfirmCodeStoreInterface,
    ) => {
      state.userSendEmailConfirmCodeComplete = true;
    },
    setUserSendEmailConfirmCodeCompleteFalse: (
      state: userSendEmailConfirmCodeStoreInterface,
    ) => {
      state.userSendEmailConfirmCodeComplete = false;
    },
    setUserSendEmailConfirmCodeCompleteNull: (
      state: userSendEmailConfirmCodeStoreInterface,
    ) => {
      state.userSendEmailConfirmCodeComplete = null;
    },
  },
});

export const {
  clearUserSendEmailConfirmCode,
  setUserSendEmailConfirmCodeLoadingTrue,
  setUserSendEmailConfirmCodeLoadingFalse,
  setUserSendEmailConfirmCodeCompleteTrue,
  setUserSendEmailConfirmCodeCompleteFalse,
  setUserSendEmailConfirmCodeCompleteNull,
} = userSendEmailConfirmCodeReduceSlice.actions;

export default userSendEmailConfirmCodeReduceSlice.reducer;
