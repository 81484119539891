import React, { useEffect, useState } from "react";
import { Box, IconButton } from "@mui/material";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import { useSelector } from "react-redux";
import appSelectors from "../../redux/app/appSelectors";
import {
  setAutoPlaySoundTestFalseDispatch,
  setAutoPlaySoundTestTrueDispatch,
} from "../../redux/app/appDispatcher";
import learningWordSoundType from "../../models/learningWordSoundType";
import learningWordSound from "../../models/learningWordSound";
import config from "../../config/config";

interface propsInterface {
  learningWordSounds: learningWordSound[];
  learningWordSoundTypes: learningWordSoundType[];
}

export default function AudioTestComponent(props: propsInterface) {
  const englishContentVariant = useSelector(appSelectors.englishContentVariant);
  const [audio, setAudio] = useState<HTMLAudioElement[]>(initAudio);

  const autoPlaySoundTest = useSelector(appSelectors.autoPlaySoundTest);

  function changeAutoplaySound() {
    autoPlaySoundTest === true
      ? setAutoPlaySoundTestFalseDispatch()
      : setAutoPlaySoundTestTrueDispatch();
  }

  useEffect(() => {
    if (autoPlaySoundTest === true) {
      setSound();
    }
  }, [autoPlaySoundTest]);

  useEffect(() => {
    setSound();
  }, []);

  useEffect(() => {
    setSound();
  }, [englishContentVariant]);

  function initAudio() {
    const newAudio: HTMLAudioElement[] = [];
    props.learningWordSounds.map((learningWordSound: learningWordSound) => {
      newAudio.push(new Audio(config.soundUrl + learningWordSound.sound));
    });
    return newAudio;
  }

  function setSound() {
    const indexType = props.learningWordSoundTypes.findIndex(function (
      type: learningWordSoundType,
    ) {
      return type.type === englishContentVariant;
    });

    const indexSound = props.learningWordSounds.findIndex(function (
      type: learningWordSound,
    ) {
      return (
        type.learning_word_sound_type_id ===
        props.learningWordSoundTypes[indexType].learning_word_sound_type_id
      );
    });

    if (
      (indexSound || indexSound === 0) &&
      autoPlaySoundTest === true &&
      typeof audio[indexSound] !== "undefined"
    ) {
      audio[indexSound].play();
    }
  }

  return (
    <>
      <Box component={"span"} sx={{ position: "relative" }}>
        <IconButton
          color="primary"
          aria-label="Play sound"
          onClick={changeAutoplaySound}
        >
          {autoPlaySoundTest === true ? (
            <VolumeUpIcon sx={{ fontSize: 40 }} />
          ) : (
            <VolumeOffIcon sx={{ fontSize: 40 }} />
          )}
        </IconButton>
      </Box>
    </>
  );
}
