import store from "../store";
import {
  clearSupportSend,
  setSupportSendLoadingFalse,
  setSupportSendLoadingTrue,
  setSupportSendComplete,
} from "./supportSendReduce";

export function clearSupportSendDispatch() {
  store.dispatch(clearSupportSend());
}

export function setSupportSendLoadingTrueDispatch() {
  store.dispatch(setSupportSendLoadingTrue());
}

export function setSupportSendLoadingFalseDispatch() {
  store.dispatch(setSupportSendLoadingFalse());
}

export function setSupportSendCompleteDispatch(value: boolean | null) {
  store.dispatch(setSupportSendComplete(value));
}
