import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import EmailInput from "../../components/forms/inputs/EmailInput";
import PasswordInput from "../../components/forms/inputs/PasswordInput";
import { useNavigate } from "react-router-dom";
import VkAuthButton from "../../components/socialAuth/vkAuth/VkAuthButton";
import {
  ROUTE_RESET_PASSWORD,
  ROUTE_SIGN_UP,
} from "../../constants/routesConstants";
import formErrorsSelectors from "../../redux/formErrors/formErrorsSelectors";
import { FormErrorsText } from "../../components/forms/FormErrorsText";
import { clearFormError } from "../../services/formErrors/formErrorsService";
import { signInHandle } from "../../services/signIn/signInService";
import userDataSelectors from "../../redux/userData/userDataSelectors";
import { LoadingButton } from "@mui/lab";

export default function SignInContainer() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const mainErrors = useSelector(formErrorsSelectors.formErrors).main;
  const authLoading = useSelector(userDataSelectors.authLoading);

  useEffect(() => {
    // clearFormError();

    return () => {
      clearFormError();
    };
  }, []);

  function changeEmailHandle(email: string) {
    setEmail(email);
  }

  function changePasswordHandle(password: string) {
    setPassword(password);
  }

  function handle() {
    signInHandle(email, password);
  }

  function toResetPassword() {
    navigate(ROUTE_RESET_PASSWORD);
  }

  function toSingUp() {
    navigate(ROUTE_SIGN_UP);
  }

  return (
    <>
      <Box
        sx={{
          marginTop: "50px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5" sx={{ mb: "25px" }}>
          {"Вход"}
        </Typography>
        <Box sx={{ mt: "5px" }}>
          <Grid container>
            <Grid
              xs={2}
              item
              sx={{
                placeSelf: "top",
                pt: "15px",
                pr: "10px",
                textAlign: "left",
                fontWeight: "bold",
                fontSize: "14px",
              }}
            >
              {"Email"}
            </Grid>
            <Grid xs={10} item>
              <EmailInput
                email={email}
                loading={authLoading}
                valueChangeHandle={changeEmailHandle}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid
              xs={2}
              item
              sx={{
                placeSelf: "top",
                pt: "15px",
                pr: "10px",
                textAlign: "left",
                fontWeight: "bold",
                fontSize: "14px",
              }}
            >
              {"Пароль:"}
            </Grid>
            <Grid xs={10} item>
              <PasswordInput
                password={password}
                loading={authLoading}
                valueChangeHandle={changePasswordHandle}
              />
            </Grid>
          </Grid>
          <FormErrorsText propertyValues={mainErrors} />
          <Box sx={{ textAlign: "center" }}>
            <LoadingButton
              loading={authLoading}
              variant="contained"
              sx={{ mt: "15px", mb: "10px" }}
              onClick={handle}
            >
              {"Вход"}
            </LoadingButton>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          m: "10px 0",
          textAlign: "center",
        }}
      >
        {"или"}
      </Box>
      <Box>
        <VkAuthButton />
      </Box>
      <Box
        sx={{
          mt: "20px",
          display: "grid",
          justifyItems: "center",
          gridAutoFlow: "column",
        }}
      >
        <Button onClick={toResetPassword}>{"Забыли пароль?"}</Button>
        <Button onClick={toSingUp}>{"Регистрация"}</Button>
      </Box>
    </>
  );
}
