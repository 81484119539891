import React from "react";
import img28 from "../../../assets/img/education/2_8.jpg";
import EducationStepBase from "./EducationStepBase";

export default function EducationStep9() {
  return (
    <EducationStepBase
      title={"Как это работает?"}
      text={'"редким"'}
      image={img28}
    />
  );
}
