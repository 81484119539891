import React, { useEffect, useState } from "react";
import { Box, Container, CssBaseline, TextField } from "@mui/material";
import Footer from "../../components/footer/Footer";
import AppHeadLine from "../../components/headLine/AppHeadLine";
import TitleLineComponent from "../../components/titleLine/TitleLineComponent";
import { TITLE_PAGE_SUPPORT } from "../../constants/titlesConstants";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import supportSendSelectors from "../../redux/supportSend/supportSendSelectors";
import supportSendData from "../../api/supportSend/supportSendData";
import supportSendApi from "../../api/supportSend/supportSendApi";

export default function SupportPage() {
  // const supportEmail = useSelector(appSelectors.supportEmail);
  const [email, setEmail] = useState("");
  const [text, setText] = useState("");

  const supportSendLoading = useSelector(
    supportSendSelectors.supportSendLoading,
  );
  const supportSendComplete = useSelector(
    supportSendSelectors.supportSendComplete,
  );

  useEffect(() => {
    if (supportSendComplete === true) {
      setEmail("");
      setText("");
    }
  }, [supportSendComplete]);

  function sendHandle() {
    if (text.length < 10) {
      return;
    }

    const data = supportSendData(text, email ?? null);
    supportSendApi(data);
  }

  return (
    <>
      <CssBaseline />
      <AppHeadLine />
      <Container maxWidth="md">
        <TitleLineComponent title={TITLE_PAGE_SUPPORT} />
      </Container>
      <Container maxWidth="sm">
        {/* <Box sx={{ mt: 10, mb: 2 }}>
          {
            "Пожалуйста, отправьте интересующий вас вопрос на указанный ниже Email."
          }
        </Box>
        <Box>
          <Link to={"mailto:" + supportEmail}>{supportEmail}</Link>
        </Box> */}
        {supportSendComplete === true ? (
          <Box
            sx={{
              backgroundColor: "success.main",
              fontSize: 20,
              color: "#fff",
              p: 3,
              mt: 3,
              mb: 3,
            }}
          >
            {"Сообщение отправлено!"}
          </Box>
        ) : (
          ""
        )}
        <Box>
          <TextField
            size={"small"}
            label="Email"
            variant="outlined"
            sx={{ width: "100%" }}
            value={email}
            onInput={(event: any) => {
              setEmail(event.target.value);
            }}
            disabled={supportSendLoading}
          />
          <Box sx={{ color: "rgba(0,0,0,0.6)", fontSize: 14 }}>
            {"Укажите Email, если хотите получить ответ"}
          </Box>
        </Box>
        <Box sx={{ mt: 2 }}>
          <TextField
            sx={{ flex: 1, fontSize: 5, width: "100%" }}
            placeholder="Введите сообщение"
            inputProps={{
              "aria-label": "Введите сообщение",
              style: { fontSize: "14px" },
            }}
            autoFocus={true}
            multiline
            rows={6}
            size="small"
            value={text}
            onInput={(event: any) => {
              setText(event.target.value);
            }}
            disabled={supportSendLoading}
          />
        </Box>
        <Box sx={{ mt: 1 }}>
          <LoadingButton
            onClick={sendHandle}
            loading={supportSendLoading}
            variant="contained"
          >
            {"Отправить"}
          </LoadingButton>
        </Box>
      </Container>
      <Footer />
    </>
  );
}
