import axios, { responseInterface } from "../axios";
import { API_USER_SEND_EMAIL_CONFIRM_CODE } from "../../constants/apiRoutConstants";
import {
  setUserSendEmailConfirmCodeCompleteFalseDispatch,
  setUserSendEmailConfirmCodeCompleteNullDispatch,
  setUserSendEmailConfirmCodeCompleteTrueDispatch,
  setUserSendEmailConfirmCodeLoadingFalseDispatch,
  setUserSendEmailConfirmCodeLoadingTrueDispatch,
} from "../../redux/userSendEmailConfirmCode/userSendEmailConfirmCodeDispatcher";
import { STATUS_OK } from "../../constants/errorCodes";
import { alertWarningDispatch } from "../../redux/alert/alertDispatcher";
import { alertAppError } from "../../constants/alertConstants";

export const userSendEmailConfirmCodeApi = () => {
  startRequest();

  axios
    .post(API_USER_SEND_EMAIL_CONFIRM_CODE)
    .then((res: any) => {
      successful(res);
    })
    .catch((res: responseInterface) => {
      unsuccessful(res);
    });
};

const startRequest = () => {
  setUserSendEmailConfirmCodeLoadingTrueDispatch();
  setUserSendEmailConfirmCodeCompleteNullDispatch();
};

const successful = (response: responseInterface<null>) => {
  response.statusCode === STATUS_OK
    ? setUserSendEmailConfirmCodeCompleteTrueDispatch()
    : setUserSendEmailConfirmCodeCompleteFalseDispatch();

  setUserSendEmailConfirmCodeLoadingFalseDispatch();
};

const unsuccessful = (res: any) => {
  alertWarningDispatch(alertAppError);
};
