import React, { useEffect } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Footer from "../../components/footer/Footer";
import Title from "../../helpers/titlesHelper";
import { indexPageTitle } from "../../constants/titlesConstants";
import AppHeadLine from "../../components/headLine/AppHeadLine";
import SectionOneTitle from "../lendingSections/SectionOneTitle";
import ConfirmEmailInfoComponent from "../../components/confirmEmailInfo/ConfirmEmailInfoComponent";
import SectionTwo from "../lendingSections/SectionTwo";
import SectionWhom from "../lendingSections/SectionWhom";
import TestingSection from "../lendingSections/TestingSection";
import SectionLine from "../lendingSections/SectionLine";

const IndexPage = () => {
  useEffect(() => {
    Title(indexPageTitle);
  });

  return (
    <>
      <CssBaseline />
      <ConfirmEmailInfoComponent />
      <AppHeadLine />
      <main>
        <SectionOneTitle />
        <SectionTwo />
        <TestingSection />
        <SectionLine />
        <SectionWhom />
      </main>
      <Footer />
    </>
  );
};

export default IndexPage;
