import axios, { responseInterface } from "../axios";
import { API_LEARN_TEST_DATA } from "../../constants/apiRoutConstants";
import { STATUS_FORBIDDEN } from "../../constants/errorCodes";
import { alertAppError } from "../../constants/alertConstants";
import { alertWarningDispatch } from "../../redux/alert/alertDispatcher";
import {
  setLearnDataAccessFalseDispatch,
  setLearnDataLoadingFalseDispatch,
  setLearnDataLoadingTrueDispatch,
  setLearnDataAccessTrueDispatch,
  setLearningWordSoundTypesDispatch,
  setLearningWordTranscriptionTypesDispatch,
} from "../../redux/learnData/learnDataDispatcher";
import learnTestDataResponse from "./learnTestDataResponse";

export const learnTestDataApi = () => {
  startRequest();

  axios
    .get(API_LEARN_TEST_DATA)
    .then((res: any) => {
      successful(res);
    })
    .catch((res: any) => {
      unsuccessful();
    });
};

const startRequest = () => {
  setLearnDataLoadingTrueDispatch();
};

function successful(response: responseInterface<learnTestDataResponse>) {
  if (response.statusCode === STATUS_FORBIDDEN) {
    setLearnDataAccessFalseDispatch();
    setLearnDataLoadingFalseDispatch();
    return;
  }

  setLearnDataAccessTrueDispatch();
  setLearningWordSoundTypesDispatch(response.data.learningWordSoundTypes);
  setLearningWordTranscriptionTypesDispatch(
    response.data.learningWordTranscriptionTypes,
  );

  setLearnDataLoadingFalseDispatch();
}

function unsuccessful() {
  alertWarningDispatch(alertAppError);
}
