import learningWordStat from "../../models/learningWordStat";
import store from "../store";
import {
  clearUserLearningWords,
  setUserLearningWordsLoadingFalse,
  setUserLearningWordsLoadingTrue,
  setLearningWords,
} from "./userLearningWordsReduce";

export function clearUserLearningWordsDispatch() {
  store.dispatch(clearUserLearningWords());
}

export function setUserLearningWordsLoadingTrueDispatch() {
  store.dispatch(setUserLearningWordsLoadingTrue());
}

export function setUserLearningWordsLoadingFalseDispatch() {
  store.dispatch(setUserLearningWordsLoadingFalse());
}

export function setLearningWordsDispatch(value: learningWordStat[]) {
  store.dispatch(setLearningWords(value));
}
