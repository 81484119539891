import { Box, Container } from "@mui/material";
import React from "react";
import ExampleTestContainer from "../exampleTest/ExampleTestContainer";

export default function TestingSection() {
  return (
    <>
      <Container maxWidth="md" sx={{ mt: 15, mb: 15 }}>
        <Box sx={{ mb: 3 }}>
          <Box sx={{ fontSize: 32 }}>{"Как это работает"}</Box>
          <Box sx={{ mt: 1, fontSize: 14, color: "rgba(0,0,0,0.8)" }}>
            {"Необходимо выбирать правильные значения показываемых слов"}
          </Box>
        </Box>
        <ExampleTestContainer />
      </Container>
    </>
  );
}
