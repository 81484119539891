import React from "react";
import img211 from "../../../assets/img/education/2_11.jpg";
import EducationStepBase from "./EducationStepBase";

export default function EducationStep12() {
  return (
    <EducationStepBase
      title={"Мои словари"}
      text={"Здесь отображается прогресс изучения выбранного словаря."}
      image={img211}
    />
  );
}
