import { createSlice } from "@reduxjs/toolkit";

export interface learnKnowWordStoreInterface {
  learnKnowWordLoading: boolean;
  learnKnowWordComplete: boolean | null;
}

export const initState: learnKnowWordStoreInterface = {
  learnKnowWordLoading: false,
  learnKnowWordComplete: null,
};

export const learnKnowWordReduceSlice = createSlice({
  name: "learnKnowWordReduce",
  initialState: initState,
  reducers: {
    clearLearnKnowWord: (state: learnKnowWordStoreInterface) => {
      state.learnKnowWordLoading = initState.learnKnowWordLoading;
      state.learnKnowWordComplete = initState.learnKnowWordComplete;
    },
    setLearnKnowWordLoadingTrue: (state: learnKnowWordStoreInterface) => {
      state.learnKnowWordLoading = true;
    },
    setLearnKnowWordLoadingFalse: (state: learnKnowWordStoreInterface) => {
      state.learnKnowWordLoading = false;
    },
    setLearnKnowWordComplete: (
      state: learnKnowWordStoreInterface,
      action: { payload: boolean | null; type: string },
    ) => {
      state.learnKnowWordComplete = action.payload;
    },
  },
});

export const {
  clearLearnKnowWord,
  setLearnKnowWordLoadingTrue,
  setLearnKnowWordLoadingFalse,
  setLearnKnowWordComplete,
} = learnKnowWordReduceSlice.actions;

export default learnKnowWordReduceSlice.reducer;
