import React from "react";
import { Box, Button, ButtonGroup } from "@mui/material";
import chooseValueWord from "../../models/chooseValueWord";
import learningWordTranslate from "../../models/learningWordTranslate";
import selectValueWord from "../../models/selectValueWord";

interface propsInterface {
  chooseValueWord: chooseValueWord;
  selectValueHandle: CallableFunction;
  selectValueWord?: selectValueWord | null;
  disable?: boolean;
}

export default function ChooseValueComponent(props: propsInterface) {
  function handle(learningWordTranslate: learningWordTranslate) {
    const selectValueWord: selectValueWord = {
      learningWord: props.chooseValueWord.learningWord,
      learningWordTranslates: props.chooseValueWord.translates,
      chooseWordTranslate: learningWordTranslate,
      success: null,
    };

    props.selectValueHandle(selectValueWord);
  }

  function colorHandle(learningWordTranslateId: number) {
    if (!props.selectValueWord) {
      return "primary";
    }

    if (
      props.selectValueWord.chooseWordTranslate.learning_word_translate_id !==
      learningWordTranslateId
    ) {
      return "primary";
    }

    if (props.selectValueWord.success === true) {
      return "success";
    }

    if (props.selectValueWord.success === false) {
      return "error";
    }

    return "primary";
  }

  return (
    <>
      <Box
        sx={{
          textAlign: "center",
          fontSize: "12px",
          marginTop: "0px",
          color: "#9a9a9a",
        }}
      >
        {"(Выберите правильное значение)"}
      </Box>
      <Box display={"flex"} justifyContent="center" sx={{ marginTop: "15px" }}>
        <ButtonGroup
          orientation="vertical"
          variant="contained"
          sx={{
            p: 2,
            width: "100%",
            maxWidth: 400,
          }}
        >
          {props.chooseValueWord?.translates.map(
            (learningWordTranslate: learningWordTranslate, index: number) => {
              return (
                <Button
                  key={index}
                  onClick={() => {
                    handle(learningWordTranslate);
                  }}
                  color={colorHandle(
                    learningWordTranslate.learning_word_translate_id,
                  )}
                  disabled={props.disable}
                  size="large"
                  sx={{
                    margin: 1,
                    minWith: 200,
                    p: 2,
                    fontWeight: "bold",
                    fontSize: 18,
                    height: 64,
                  }}
                >
                  {learningWordTranslate.translate}
                </Button>
              );
            },
          )}
        </ButtonGroup>
      </Box>
    </>
  );
}
