import { Box, Divider, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import userDataSelectors from "../../../redux/userData/userDataSelectors";
import { LoadingButton } from "@mui/lab";
import PasswordRepeatInput from "../../forms/inputs/PasswordRepeatInput";
import PasswordInput from "../../forms/inputs/PasswordInput";
import CurrentPasswordInput from "../../forms/inputs/CurrentPasswordInput";
import { updatePasswordHandle } from "../../../services/updatePassword/updatePasswordService";
import { alertSuccessDispatch } from "../../../redux/alert/alertDispatcher";

export default function SettingPasswordChangeLayout() {
  const [currentPassword, setCurrentPassword] = useState("");
  const [password, setPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const updatePasswordLoading = useSelector(
    userDataSelectors.updatePasswordLoading,
  );
  const success = useSelector(userDataSelectors.updatePasswordSuccess);

  useEffect(() => {
    if (success === true) {
      alertSuccessDispatch("Новый пароль установлен");
      clear();
    }
  }, [success]);

  function changeCurrentPasswordHandle(currentPassword: string) {
    setCurrentPassword(currentPassword);
  }

  function changePasswordHandle(password: string) {
    setPassword(password);
  }

  function changePasswordRepeatHandle(passwordRepeat: string) {
    setPasswordRepeat(passwordRepeat);
  }

  function clear() {
    changeCurrentPasswordHandle("");
    changePasswordHandle("");
    changePasswordRepeatHandle("");
  }

  function handle() {
    updatePasswordHandle(currentPassword, password, passwordRepeat);
  }

  return (
    <>
      <Divider
        textAlign="left"
        sx={{ mt: "25px", mb: "25px", fontSize: "18px" }}
      >
        {"Изменение пароля"}
      </Divider>
      <Grid container>
        <Grid
          xs={4}
          item
          sx={{
            placeSelf: "top",
            pt: "15px",
            pr: "10px",
            textAlign: "right",
            fontWeight: "bold",
            fontSize: "14px",
          }}
        >
          {"Старый пароль"}
        </Grid>
        <Grid xs={6} item>
          <CurrentPasswordInput
            currentPassword={currentPassword}
            loading={updatePasswordLoading}
            valueChangeHandle={changeCurrentPasswordHandle}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid
          xs={4}
          item
          sx={{
            placeSelf: "top",
            pt: "15px",
            pr: "10px",
            textAlign: "right",
            fontWeight: "bold",
            fontSize: "14px",
          }}
        >
          {"Новый пароль"}
        </Grid>
        <Grid xs={6} item>
          <PasswordInput
            password={password}
            loading={updatePasswordLoading}
            valueChangeHandle={changePasswordHandle}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid
          xs={4}
          item
          sx={{
            placeSelf: "top",
            pt: "15px",
            pr: "10px",
            textAlign: "right",
            fontWeight: "bold",
            fontSize: "14px",
          }}
        >
          {"Новый пароль (повтор)"}
        </Grid>
        <Grid xs={6} item>
          <PasswordRepeatInput
            passwordRepeat={passwordRepeat}
            loading={updatePasswordLoading}
            valueChangeHandle={changePasswordRepeatHandle}
          />
        </Grid>
      </Grid>
      <Box sx={{ textAlign: "center" }}>
        <LoadingButton
          loading={updatePasswordLoading}
          variant="contained"
          onClick={handle}
        >
          {"Сохранить"}
        </LoadingButton>
      </Box>
    </>
  );
}
