import axios, { responseInterface } from "../axios";
import { API_LEARN_TEST_NEXT } from "../../constants/apiRoutConstants";
import { STATUS_FORBIDDEN } from "../../constants/errorCodes";
import { alertAppError } from "../../constants/alertConstants";
import { alertWarningDispatch } from "../../redux/alert/alertDispatcher";
import learnTestNextResponse from "./learnTestNextResponse";
import {
  setLearnNextAccessFalseDispatch,
  setLearnNextAccessTrueDispatch,
  setLearnNextDispatch,
  setLearnNextLoadingFalseDispatch,
  setLearnNextLoadingTrueDispatch,
} from "../../redux/learnNext/learnNextDispatcher";

export const learnTestNextApi = () => {
  startRequest();

  axios
    .get(API_LEARN_TEST_NEXT)
    .then((res: any) => {
      successful(res);
    })
    .catch((res: any) => {
      unsuccessful();
    });
};

const startRequest = () => {
  setLearnNextLoadingTrueDispatch();
};

function successful(response: responseInterface<learnTestNextResponse>) {
  if (response.statusCode === STATUS_FORBIDDEN) {
    setLearnNextAccessFalseDispatch();
    setLearnNextLoadingFalseDispatch();
    return;
  }

  setLearnNextAccessTrueDispatch();
  setLearnNextDispatch(response.data.wordChooseTranslate);

  setLearnNextLoadingFalseDispatch();
}

function unsuccessful() {
  alertWarningDispatch(alertAppError);
}
