import React, { useState } from "react";
import { Box, Button } from "@mui/material";
import logo from "../../assets/img/logo.webp";
import { Link } from "react-router-dom";
import config from "../../config/config";
import NewWindow from "react-new-window";

export default function AppOldVersionComponent() {
  return (
    <>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxWidth: 200,
          width: "100%",
          textAlign: "center",
        }}
      >
        <img src={logo} style={{ width: 100 }} />
        <Box sx={{ color: "rgba(0,0,0,0.5)", fontSize: 12 }}>{"eayse.ru"}</Box>
      </Box>
      <Box
        sx={{
          position: "absolute",
          bottom: "10%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxWidth: 200,
          width: "100%",
          textAlign: "center",
        }}
      >
        <Box sx={{ mt: 2, mb: 1 }}>{"Версия приложения устарела."}</Box>
        <Link to={config.ruStoreUrl} target="_blank">
          {"Обновить"}
        </Link>
      </Box>
    </>
  );
}
