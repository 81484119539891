import store from "../store";
import {
  clearUserSendEmailConfirmCode,
  setUserSendEmailConfirmCodeCompleteFalse,
  setUserSendEmailConfirmCodeCompleteNull,
  setUserSendEmailConfirmCodeCompleteTrue,
  setUserSendEmailConfirmCodeLoadingFalse,
  setUserSendEmailConfirmCodeLoadingTrue,
} from "./userSendEmailConfirmCodeReduce";

export function clearUserSendEmailConfirmCodeDispatch() {
  store.dispatch(clearUserSendEmailConfirmCode());
}
export function setUserSendEmailConfirmCodeLoadingTrueDispatch() {
  store.dispatch(setUserSendEmailConfirmCodeLoadingTrue());
}
export function setUserSendEmailConfirmCodeLoadingFalseDispatch() {
  store.dispatch(setUserSendEmailConfirmCodeLoadingFalse());
}
export function setUserSendEmailConfirmCodeCompleteTrueDispatch() {
  store.dispatch(setUserSendEmailConfirmCodeCompleteTrue());
}
export function setUserSendEmailConfirmCodeCompleteFalseDispatch() {
  store.dispatch(setUserSendEmailConfirmCodeCompleteFalse());
}
export function setUserSendEmailConfirmCodeCompleteNullDispatch() {
  store.dispatch(setUserSendEmailConfirmCodeCompleteNull());
}
