import React from "react";
import img23 from "../../../assets/img/education/2_3.jpg";
import EducationStepBase from "./EducationStepBase";

export default function EducationStep4() {
  return (
    <EducationStepBase
      title={"Как это работает?"}
      text={"Правильное - зеленым."}
      image={img23}
    />
  );
}
