import React from "react";
import { YMInitializer } from "react-yandex-metrika";

export default function MetricComponent() {
  return (
    <>
      <YMInitializer
        accounts={[39484680]}
        options={{
          clickmap: true,
          trackLinks: true,
          accurateTrackBounce: true,
          trackHash: true,
        }}
        version="2"
      />
    </>
  );
}
