import React, { useEffect, useState } from "react";
import { Box, ButtonGroup, Skeleton } from "@mui/material";
import ChooseValueComponent from "../../components/chooseValue/ChooseValueComponent";
import TranscriptionsComponent from "../../components/transcriptions/TranscriptionsComponent";
import { useSelector } from "react-redux";
import learnNextSelectors from "../../redux/learnNext/learnNextSelectors";
import learnDataSelectors from "../../redux/learnData/learnDataSelectors";
import selectValueWord from "../../models/selectValueWord";
import learnCheckSelectors from "../../redux/learnCheck/learnCheckSelectors";
import { learnTestDataApi } from "../../api/learnTestData/learnTestDataApi";
import { clearLearnDataDispatch } from "../../redux/learnData/learnDataDispatcher";
import { clearLearnNextDispatch } from "../../redux/learnNext/learnNextDispatcher";
import { clearLearnCheckDispatch } from "../../redux/learnCheck/learnCheckDispatcher";
import learnTestCheckData from "../../api/learnTestCheck/learnTestCheckData";
import { learnTestCheckApi } from "../../api/learnTestCheck/learnTestCheckApi";
import { learnTestNextApi } from "../../api/learnTestNext/learnTestNextApi";
import { setAutoPlaySoundTestFalseDispatch } from "../../redux/app/appDispatcher";
import AudioTestComponent from "../../components/audioComponent/AudioTestComponent";

export default function ExampleTestContainer() {
  const [selectValueWord, setSelectValueWord] =
    useState<selectValueWord | null>(null);

  const chooseValueWord = useSelector(learnNextSelectors.learnNext);
  const learnNextLoading = useSelector(learnNextSelectors.learnNextLoading);
  const learningWordTranscriptionTypes = useSelector(
    learnDataSelectors.learningWordTranscriptionTypes,
  );
  const learningWordSoundTypes = useSelector(
    learnDataSelectors.learningWordSoundTypes,
  );
  const learnCheckLoading = useSelector(learnCheckSelectors.learnCheckLoading);
  const learnDataLoading = useSelector(learnDataSelectors.learnDataLoading);
  const success = useSelector(learnCheckSelectors.success);

  useEffect(() => {
    learnTestDataApi();

    return () => {
      setAutoPlaySoundTestFalseDispatch();
      clearLearnDataDispatch();
      clearLearnNextDispatch();
      clearLearnCheckDispatch();
    };
  }, []);

  useEffect(() => {
    if (learnDataLoading === false && chooseValueWord === null) {
      learnTestNextApi();
    }
  }, [learnDataLoading]);

  useEffect(() => {
    if (selectValueWord !== null) {
      setSelectValueWord({
        ...selectValueWord,
        success: success,
      });
    }

    if (success === true) {
      setTimeout(() => {
        learnTestNextApi();
        setSelectValueWord(null);
      }, 800);
    }
  }, [success]);

  function selectValueHandle(select: selectValueWord) {
    setSelectValueWord(select);

    const data = learnTestCheckData(
      select.learningWord.learning_word_id,
      select.chooseWordTranslate.learning_word_translate_id,
    );

    learnTestCheckApi(data);
  }

  function ButtonChooseSkeleton() {
    return (
      <Skeleton
        variant="rounded"
        sx={{
          margin: 1,
          minWith: 200,
          p: 2,
          fontWeight: "bold",
          fontSize: 18,
          height: 64,
        }}
      />
    );
  }

  function LoadingLayout() {
    return (
      <>
        <Box
          sx={{
            mt: 2,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            height: 61,
          }}
        >
          <Box sx={{ fontSize: 40, textTransform: "uppercase" }}>
            <Skeleton variant="text" sx={{ width: 250 }} />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            textTransform: "uppercase",
            fontSize: 20,
            fontStyle: "italic",
            mb: 2,
            height: 32,
          }}
        >
          <Skeleton variant="text" sx={{ width: 150 }} />
        </Box>
        <Box
          sx={{
            textAlign: "center",
            fontSize: "12px",
            marginTop: "0px",
            color: "#9a9a9a",
          }}
        >
          {"(Выберите правильное значение)"}
        </Box>
        <Box
          display={"flex"}
          justifyContent="center"
          sx={{ marginTop: "15px" }}
        >
          <ButtonGroup
            orientation="vertical"
            variant="contained"
            sx={{
              p: 2,
              width: "100%",
              maxWidth: 400,
            }}
          >
            <ButtonChooseSkeleton />
            <ButtonChooseSkeleton />
            <ButtonChooseSkeleton />
            <ButtonChooseSkeleton />
          </ButtonGroup>
        </Box>
      </>
    );
  }

  return (
    <>
      {chooseValueWord !== null && learnNextLoading === false ? (
        <>
          <Box
            sx={{
              mt: 2,
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              height: 61,
            }}
          >
            <Box sx={{ fontSize: 40, textTransform: "uppercase" }}>
              <AudioTestComponent
                learningWordSounds={chooseValueWord.sounds}
                learningWordSoundTypes={learningWordSoundTypes}
              />
              {chooseValueWord.learningWord.word}
            </Box>
          </Box>
          <Box
            sx={{
              textAlign: "center",
              fontSize: 20,
              fontStyle: "italic",
              mb: 2,
              height: 32,
            }}
          >
            <TranscriptionsComponent
              transcriptions={chooseValueWord.transcriptions}
              learningWordTranscriptionTypes={learningWordTranscriptionTypes}
            />
          </Box>
          <ChooseValueComponent
            chooseValueWord={chooseValueWord}
            selectValueHandle={selectValueHandle}
            selectValueWord={selectValueWord}
            disable={learnCheckLoading || learnNextLoading}
          />
        </>
      ) : (
        <LoadingLayout />
      )}
    </>
  );
}
