import React, { useEffect } from "react";
import { Box, Divider, Typography } from "@mui/material";

interface titleLineComponentProps {
  title: string;
}

function TitleLineComponent(props: titleLineComponentProps) {
  useEffect(() => {
    document.title = props.title;
  }, []);

  return (
    <>
      <Box sx={{ mt: 1, mb: 3 }}>
        <Box sx={{ fontSize: 24, pl: 3 }}>{props.title}</Box>
        <Divider />
        {/* <Divider textAlign="left">
          {props.title}
        </Divider> */}
      </Box>
    </>
  );
}

export default TitleLineComponent;
