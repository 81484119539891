import React from "react";
import img212 from "../../../assets/img/education/2_12.jpg";
import EducationStepBase from "./EducationStepBase";

export default function EducationStep13() {
  return (
    <EducationStepBase
      title={"Мои словари"}
      text={
        'Если нажать на кнопку "Создать словарь", то перейдете в раздел создания словаря.'
      }
      image={img212}
    />
  );
}
