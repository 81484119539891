import { alertAppError } from "../../constants/alertConstants";
import { API_USER_LEARNING_WORDS } from "../../constants/apiRoutConstants";
import { alertWarningDispatch } from "../../redux/alert/alertDispatcher";
import {
  setLearningWordsDispatch,
  setUserLearningWordsLoadingFalseDispatch,
  setUserLearningWordsLoadingTrueDispatch,
} from "../../redux/userLearningWords/userLearningWordsDispatcher";
import axios, { responseInterface } from "../axios";
import userLearningWordsResponse from "./userLearningWordsResponse";

export default function userLearningWordsApi() {
  startRequest();

  axios
    .get(API_USER_LEARNING_WORDS)
    .then((res: any) => {
      successful(res);
    })
    .catch(() => {
      unsuccessful();
    });
}

const startRequest = () => {
  setUserLearningWordsLoadingTrueDispatch();
};

const successful = (response: responseInterface<userLearningWordsResponse>) => {
  setLearningWordsDispatch(response.data.learningWords);

  setUserLearningWordsLoadingFalseDispatch();
};

const unsuccessful = () => {
  alertWarningDispatch(alertAppError);
};
