import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  ROUTE_GROUPS_PERSONAL,
  ROUTE_LEARN,
  ROUTE_SIGN_IN,
} from "../../constants/routesConstants";
import { useSelector } from "react-redux";
import { storeInterface } from "../../redux/store";

export default function SectionLine() {
  const navigate = useNavigate();
  const token = useSelector((state: storeInterface) => state.app.token);

  const toLearn = () => {
    navigate(ROUTE_LEARN);
  };

  const toSignIn = () => {
    navigate(ROUTE_SIGN_IN);
  };

  const toGroupsPersonal = () => {
    navigate(ROUTE_GROUPS_PERSONAL);
  };

  const ButtonToLearn = () => {
    return (
      <Button variant="contained" onClick={toLearn} size="small">
        {"Продолжить"}
      </Button>
    );
  };

  const ButtonToSignIn = () => {
    return (
      <Button variant="contained" onClick={toSignIn} size="small">
        {"Попробовать"}
      </Button>
    );
  };

  const ButtonToGroupsPersonal = () => {
    return (
      <Button variant="contained" onClick={toGroupsPersonal} size="small">
        {"К словарям"}
      </Button>
    );
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          verticalAlign: "top",
          backgroundColor: "primary.main",
          p: 3,
        }}
      >
        <Box sx={{ color: "#fff", fontSize: 24 }}>
          {"Простые и доступные методы"}
        </Box>
      </Box>
    </>
  );
}
