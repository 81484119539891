import React from "react";
import img27 from "../../../assets/img/education/2_7.jpg";
import EducationStepBase from "./EducationStepBase";

export default function EducationStep8() {
  return (
    <EducationStepBase
      title={"Как это работает?"}
      text={'Новое слово может быть "обычным"'}
      image={img27}
    />
  );
}
