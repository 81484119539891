import { Box } from "@mui/material";
import React from "react";

interface propsInterface {
  title: string;
  text: string;
  image?: any;
}

export default function EducationStepBase(props: propsInterface) {
  return (
    <>
      <Box>
        <Box
          sx={{
            fontSize: 26,
            mb: 2,
            textAlign: "left",
            borderBottom: "2px rgba(0,0,0,0.3) solid",
          }}
        >
          {props.title}
        </Box>
        <Box sx={{ fontSize: 18 }}>{props.text}</Box>
        <Box sx={{ textAlign: "center", mt: 2 }}>
          {props.image ? <img src={props.image} style={{ width: 200 }} /> : ""}
        </Box>
      </Box>
    </>
  );
}
