import React from "react";
import img26 from "../../../assets/img/education/2_6.jpg";
import EducationStepBase from "./EducationStepBase";

export default function EducationStep7() {
  return (
    <EducationStepBase
      title={"Как это работает?"}
      text={
        "Это строка прогресса до появления нового слова. С каждым правильным выбором значения, прогресс будет увеличиваться. Когда дойдет до конца, появится новое слово."
      }
      image={img26}
    />
  );
}
