import { storeInterface } from "../store";

const userCompleteEducationLoading = (state: storeInterface) =>
  state.userCompleteEducation.userCompleteEducationLoading;
const userCompleteEducationComplete = (state: storeInterface) =>
  state.userCompleteEducation.userCompleteEducationComplete;

export default {
  userCompleteEducationLoading,
  userCompleteEducationComplete,
};
