import React from "react";
import img218 from "../../../assets/img/education/2_18.jpg";
import EducationStepBase from "./EducationStepBase";

export default function EducationStep19() {
  return (
    <EducationStepBase
      title={"Мои словари"}
      text={
        "Через какое-то время слова будут добавлены в словарь. Новые слова будут ниже раннее добавленных слов. Учтите, что некоторые слова должны быть подтверждены администратором. Такие слова помечены соответствующим символом. Не получится приступить к изучению словаря, пока все такие слова не будут проверены."
      }
      image={img218}
    />
  );
}
