import { authResetPasswordApi } from "../../api/authResetPassword/authResetPasswordApi";
import authResetPasswordData from "../../api/authResetPassword/authResetPasswordData";
import {
  isEmailIncorrectFormat,
  isEmailValueEmpty,
} from "../formCheckService/formCheckService";
import { clearFormError } from "../formErrors/formErrorsService";

export function resetPasswordHandle(email: string) {
  clearFormError();

  if (isEmailValueEmpty(email)) {
    return;
  }

  if (isEmailIncorrectFormat(email)) {
    return;
  }

  const data = authResetPasswordData(email);
  authResetPasswordApi(data);
}
