import React, { useEffect, useState } from "react";
import {
  Alert,
  Box,
  ButtonGroup,
  Container,
  CssBaseline,
  Divider,
  Paper,
  Skeleton,
} from "@mui/material";
import Footer from "../../components/footer/Footer";
import AppHeadLine from "../../components/headLine/AppHeadLine";
import TitleLineComponent from "../../components/titleLine/TitleLineComponent";
import { TITLE_PAGE_LEARN } from "../../constants/titlesConstants";
import AppLoader from "../../components/loader/AppLoader";
import { learnDataApi } from "../../api/learnData/learnDataApi";
import { clearLearnDataDispatch } from "../../redux/learnData/learnDataDispatcher";
import { clearLearnNextDispatch } from "../../redux/learnNext/learnNextDispatcher";
import { clearLearnCheckDispatch } from "../../redux/learnCheck/learnCheckDispatcher";
import NewLearningWordWordLayout from "../../components/layouts/learn/NewLearningWordWordLayout";
import newLearningWord from "../../models/newLearningWord";
import ChooseValueComponent from "../../components/chooseValue/ChooseValueComponent";
import selectValueWord from "../../models/selectValueWord";
import LearnProgressLayout from "../../components/layouts/learn/LearnProgressLayout";
import { useSelector } from "react-redux";
import learnDataSelectors from "../../redux/learnData/learnDataSelectors";
import learnCheckSelectors from "../../redux/learnCheck/learnCheckSelectors";
import learnNextSelectors from "../../redux/learnNext/learnNextSelectors";
import { learnNextApi } from "../../api/learnNext/learnNextApi";
import learnCheckData from "../../api/learnCheck/learnCheckData";
import { learnCheckApi } from "../../api/learnCheck/learnCheckApi";
import LastNewLearningWordLayout from "../../components/layouts/learn/LastNewLearningWordLayout";
import { Link } from "react-router-dom";
import { ROUTE_PAY } from "../../constants/routesConstants";
import TranscriptionsComponent from "../../components/transcriptions/TranscriptionsComponent";
import AudioMultipleComponent from "../../components/audioComponent/AudioMultipleComponent";
import EnglishVariantChecker from "../../components/layouts/learn/EnglishVariantChecker";
import ConfirmEmailInfoComponent from "../../components/confirmEmailInfo/ConfirmEmailInfoComponent";
import VkPixelLearn from "../../components/vkPixel/VkPixelLearn";
import appSelectors from "../../redux/app/appSelectors";

export default function LearnPage() {
  const [isNewWord, setIsNewWord] = useState(false);
  const [newLearningWord, setNewLearningWord] =
    useState<newLearningWord | null>(null);
  const [progress, setProgress] = useState(0);
  const [selectValueWord, setSelectValueWord] =
    useState<selectValueWord | null>(null);

  const learnDataLoading = useSelector(learnDataSelectors.learnDataLoading);
  const lastNewLearningWord = useSelector(
    learnDataSelectors.lastNewLearningWord,
  );
  const updateNewWord = useSelector(learnDataSelectors.updateNewWord);
  const learnDataProgress = useSelector(learnDataSelectors.progress);
  const learningWordSoundTypes = useSelector(
    learnDataSelectors.learningWordSoundTypes,
  );
  const learningWordTranscriptionTypes = useSelector(
    learnDataSelectors.learningWordTranscriptionTypes,
  );

  const chooseValueWord = useSelector(learnNextSelectors.learnNext);
  const learnNextLoading = useSelector(learnNextSelectors.learnNextLoading);

  const learnCheckNewLearningWord = useSelector(
    learnCheckSelectors.newLearningWord,
  );
  const learnCheckLoading = useSelector(learnCheckSelectors.learnCheckLoading);
  const learnCheckProgress = useSelector(learnCheckSelectors.progress);
  const success = useSelector(learnCheckSelectors.success);

  const paidAccess = useSelector(learnDataSelectors.paidAccess);

  const completeLearningWordChooseTrue = useSelector(
    appSelectors.completeLearningWordChooseTrue,
  );

  useEffect(() => {
    learnDataApi();

    return () => {
      clear();
    };
  }, []);

  function clear() {
    clearLearnNextDispatch();
    clearLearnDataDispatch();
    clearLearnCheckDispatch();
  }

  useEffect(() => {
    setNewLearningWord(lastNewLearningWord);
  }, [lastNewLearningWord]);

  useEffect(() => {
    if (learnCheckNewLearningWord !== null) {
      setNewLearningWord(learnCheckNewLearningWord);
      setIsNewWord(true);
    }
  }, [learnCheckNewLearningWord]);

  useEffect(() => {
    setIsNewWord(updateNewWord);
  }, [updateNewWord]);

  useEffect(() => {
    setProgress(learnDataProgress);
  }, [learnDataProgress]);

  useEffect(() => {
    setProgress(learnCheckProgress);
  }, [learnCheckProgress]);

  useEffect(() => {
    if (selectValueWord === null) {
      return;
    }

    const cloneSelectValueWord = {
      ...selectValueWord,
    };

    cloneSelectValueWord.success = success;
    setSelectValueWord(cloneSelectValueWord);

    if (success === true) {
      setTimeout(() => {
        learnNextApi();
        setSelectValueWord(null);
      }, 800);
    }
  }, [success]);

  useEffect(() => {
    if (learnDataLoading === false && chooseValueWord === null) {
      learnNextApi();
    }
  }, [learnDataLoading]);

  function newWordHandle() {
    learnDataApi();
    setIsNewWord(false);
  }

  function knowWordHandle() {
    clear();
    learnDataApi();
  }

  function isStart() {
    return learnDataLoading === false;
  }

  function isNewWordShow() {
    return isStart() && isNewWord === true;
  }

  function isLearnLayoutShow() {
    return isStart() && isNewWord === false && !isCompleteLearn();
  }

  function isCompleteLearn() {
    return chooseValueWord === null && learnNextLoading === false;
  }

  function selectValueHandle(select: selectValueWord) {
    setSelectValueWord(select);

    const data = learnCheckData(
      select.learningWord.learning_word_id,
      select.chooseWordTranslate.learning_word_translate_id,
    );

    learnCheckApi(data);
  }

  function isShowPaidLayout() {
    return paidAccess === false;
  }

  function ButtonChooseSkeleton() {
    return (
      <Skeleton
        variant="rounded"
        sx={{
          margin: 1,
          minWith: 200,
          p: 2,
          fontWeight: "bold",
          fontSize: 18,
          height: 64,
        }}
      />
    );
  }

  function LoadingLayout() {
    return (
      <>
        <Box
          sx={{
            mt: 2,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            height: 61,
          }}
        >
          <Box sx={{ fontSize: 40, textTransform: "uppercase" }}>
            <Skeleton variant="text" sx={{ width: 250 }} />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            textTransform: "uppercase",
            fontSize: 20,
            fontStyle: "italic",
            mb: 2,
            height: 32,
          }}
        >
          <Skeleton variant="text" sx={{ width: 150 }} />
        </Box>
        <Box
          sx={{
            textAlign: "center",
            fontSize: "12px",
            marginTop: "0px",
            color: "#9a9a9a",
          }}
        >
          {"(Выберите правильное значение)"}
        </Box>
        <Box
          display={"flex"}
          justifyContent="center"
          sx={{ marginTop: "15px" }}
        >
          <ButtonGroup
            orientation="vertical"
            variant="contained"
            sx={{
              p: 2,
              width: "100%",
              maxWidth: 400,
            }}
          >
            <ButtonChooseSkeleton />
            <ButtonChooseSkeleton />
            <ButtonChooseSkeleton />
            <ButtonChooseSkeleton />
          </ButtonGroup>
        </Box>
      </>
    );
  }

  function PaidLayout() {
    return (
      <>
        <Alert severity="warning">
          {"Новые слова не будут появляться."}
          <Box component={"span"} sx={{ pl: 2 }}>
            <Link to={ROUTE_PAY}>{"Оплатитe подписку"}</Link>
          </Box>
        </Alert>
      </>
    );
  }

  return (
    <>
      <CssBaseline />
      <ConfirmEmailInfoComponent />
      <AppHeadLine />
      <Container maxWidth="md" sx={{ minHeight: 550 }}>
        <TitleLineComponent title={TITLE_PAGE_LEARN} />
        {isShowPaidLayout() ? <PaidLayout /> : ""}
        {isNewWordShow() && newLearningWord ? (
          <NewLearningWordWordLayout
            newLearningWord={newLearningWord}
            continueHandler={newWordHandle}
            learningWordTranscriptionTypes={learningWordTranscriptionTypes}
            learningWordSoundTypes={learningWordSoundTypes}
            knowWordHandle={knowWordHandle}
          />
        ) : (
          ""
        )}
        {isLearnLayoutShow() ? (
          <>
            <EnglishVariantChecker />
            <LearnProgressLayout progress={progress} />
            {chooseValueWord !== null && learnNextLoading === false ? (
              <>
                <Box
                  sx={{
                    mt: 2,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    height: 61,
                  }}
                >
                  <Box sx={{ fontSize: 40, textTransform: "uppercase" }}>
                    <AudioMultipleComponent
                      learningWordSounds={chooseValueWord.sounds}
                      learningWordSoundTypes={learningWordSoundTypes}
                    />
                    {chooseValueWord.learningWord.word}
                  </Box>
                </Box>
                <Box
                  sx={{
                    textAlign: "center",
                    fontSize: 20,
                    fontStyle: "italic",
                    mb: 2,
                    height: 32,
                  }}
                >
                  <TranscriptionsComponent
                    transcriptions={chooseValueWord.transcriptions}
                    learningWordTranscriptionTypes={
                      learningWordTranscriptionTypes
                    }
                  />
                </Box>
                <ChooseValueComponent
                  chooseValueWord={chooseValueWord}
                  selectValueHandle={selectValueHandle}
                  selectValueWord={selectValueWord}
                  disable={learnCheckLoading || learnNextLoading}
                />
              </>
            ) : (
              ""
            )}
            {learnNextLoading === true ? <LoadingLayout /> : ""}
          </>
        ) : (
          ""
        )}
        {isLearnLayoutShow() && newLearningWord ? (
          <Box sx={{ mt: "40px" }}>
            <Divider />
            <LastNewLearningWordLayout newLearningWord={newLearningWord} />
          </Box>
        ) : (
          ""
        )}
        {!isStart() ? (
          <>
            <AppLoader />
          </>
        ) : (
          ""
        )}
        {isCompleteLearn() ? (
          <>
            <Box
              sx={{
                FontSize: 24,
                textAlign: "center",
                mt: 4,
                mb: 4,
                fontSize: 24,
                fontWeight: "bold",
              }}
            >
              {"Вы выучили все слова"}
            </Box>
            <Box sx={{ FontSize: 24, textAlign: "center", mb: 1 }}>
              {"Это значит, что значение каждого изученного слово вы правильно выбрали более " +
                completeLearningWordChooseTrue +
                " раз. Вы навсегда запомнили заничения этих слов. Вам больше нет смысла показывать эти слова."}
            </Box>
          </>
        ) : (
          ""
        )}
      </Container>
      <Footer />
      <VkPixelLearn />
    </>
  );
}
