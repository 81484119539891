import { storeInterface } from "../store";

const appDataLoading = (state: storeInterface) => state.app.appDataLoading;
const showAppLoader = (state: storeInterface) => state.app.showAppLoader;
const token = (state: storeInterface) => state.app.token;
const tokenLoading = (state: storeInterface) => state.app.tokenLoading;
const tokenComplete = (state: storeInterface) => state.app.tokenComplete;
const temporaryToken = (state: storeInterface) => state.app.temporaryToken;
const autoPlaySound = (state: storeInterface) => state.app.autoPlaySound;
const autoPlaySoundTest = (state: storeInterface) =>
  state.app.autoPlaySoundTest;
const autoPlayTypeSound = (state: storeInterface) =>
  state.app.autoPlayTypeSound;
const englishContentVariant = (state: storeInterface) =>
  state.app.englishContentVariant;
const subscribeCost = (state: storeInterface) => state.app.subscribeCost;
const supportEmail = (state: storeInterface) => state.app.supportEmail;
const logoutLoading = (state: storeInterface) => state.app.logoutLoading;
const appVersion = (state: storeInterface) => state.app.appVersion;
const completeLearningWordChooseTrue = (state: storeInterface) =>
  state.app.completeLearningWordChooseTrue;
const appInUpdate = (state: storeInterface) => state.app.appInUpdate;

export default {
  appDataLoading,
  showAppLoader,
  token,
  tokenLoading,
  tokenComplete,
  temporaryToken,
  autoPlaySound,
  autoPlaySoundTest,
  autoPlayTypeSound,
  englishContentVariant,
  subscribeCost,
  supportEmail,
  logoutLoading,
  appVersion,
  completeLearningWordChooseTrue,
  appInUpdate,
};
