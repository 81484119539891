import store from "../store";
import {
  setAppDataLoadingFalse,
  setAppDataLoadingTrue,
  setAppVersion,
  setAutoPlaySoundFalse,
  setAutoPlaySoundTestFalse,
  setAutoPlaySoundTestTrue,
  setAutoPlaySoundTrue,
  setCompleteLearningWordChooseTrue,
  setEnglishContentVariant,
  setLogoutLoading,
  setSubscribeCost,
  setSupportEmail,
  setTokenComplete,
  setTokenLoadingFalse,
  setTokenLoadingTrue,
  temporaryTokenLoadingFalse,
  temporaryTokenLoadingTrue,
  setAppInUpdate,
} from "./appReduce";

export function setAppDataLoadingTrueDispatch() {
  store.dispatch(setAppDataLoadingTrue());
}

export function setAppDataLoadingFalseDispatch() {
  store.dispatch(setAppDataLoadingFalse());
}

export function setAutoPlaySoundTrueDispatch() {
  store.dispatch(setAutoPlaySoundTrue());
}

export function setAutoPlaySoundFalseDispatch() {
  store.dispatch(setAutoPlaySoundFalse());
}

export function setAutoPlaySoundTestTrueDispatch() {
  store.dispatch(setAutoPlaySoundTestTrue());
}

export function setAutoPlaySoundTestFalseDispatch() {
  store.dispatch(setAutoPlaySoundTestFalse());
}

export function setEnglishContentVariantDispatch(
  value: "british" | "american",
) {
  store.dispatch(setEnglishContentVariant(value));
}

export function setSubscribeCostDispatch(value: number) {
  store.dispatch(setSubscribeCost(value));
}

export function setSupportEmailDispatch(value: string) {
  store.dispatch(setSupportEmail(value));
}

export function setTokenLoadingTrueDispatch() {
  store.dispatch(setTokenLoadingTrue());
}

export function setTokenLoadingFalseDispatch() {
  store.dispatch(setTokenLoadingFalse());
}

export function setTokenCompleteDispatch(value: boolean | null) {
  store.dispatch(setTokenComplete(value));
}

export function setLogoutLoadingDispatch(value: boolean) {
  store.dispatch(setLogoutLoading(value));
}

export function setAppVersionDispatch(value: number) {
  store.dispatch(setAppVersion(value));
}

export function temporaryTokenLoadingTrueDispatch() {
  store.dispatch(temporaryTokenLoadingTrue());
}

export function temporaryTokenLoadingFalseDispatch() {
  store.dispatch(temporaryTokenLoadingFalse());
}

export function setCompleteLearningWordChooseTrueDispatch(value: number) {
  store.dispatch(setCompleteLearningWordChooseTrue(value));
}

export function setAppInUpdateDispatch(value: boolean) {
  store.dispatch(setAppInUpdate(value));
}
