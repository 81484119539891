import store from "../store";
import {
  clearAuthResetPassword,
  setAuthResetPasswordComplete,
  setAuthResetPasswordLoading,
} from "./authResetPasswordReduce";

export function clearAuthResetPasswordDispatch() {
  store.dispatch(clearAuthResetPassword());
}

export function setAuthResetPasswordCompleteDispatch(value: boolean | null) {
  store.dispatch(setAuthResetPasswordComplete(value));
}

export function setAuthResetPasswordLoadingDispatch(value: boolean) {
  store.dispatch(setAuthResetPasswordLoading(value));
}
