import { Box } from "@mui/material";
import React from "react";
import AssignmentLateIcon from "@mui/icons-material/AssignmentLate";

export default function SystemWorksComponent() {
  return (
    <>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxWidth: 200,
          width: "100%",
          textAlign: "center",
        }}
      >
        <AssignmentLateIcon sx={{ fontSize: 100, color: "#ffa300" }} />
        <Box
          sx={{
            mt: 2,
            fontSize: 16,
            textTransform: "uppercase",
          }}
        >
          {"Технические работы"}
        </Box>
      </Box>
    </>
  );
}
