import React from "react";
import img214 from "../../../assets/img/education/2_14.jpg";
import EducationStepBase from "./EducationStepBase";

export default function EducationStep15() {
  return (
    <EducationStepBase
      title={"Мои словари"}
      text={
        "Вы будете перенаправлены на страницу редактирования созданного словаря. Теперь словарь необходимо заполнить словами."
      }
      image={img214}
    />
  );
}
