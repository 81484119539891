import { createSlice } from "@reduxjs/toolkit";
import learningWordStat from "../../models/learningWordStat";

export interface userLearningWordsStoreInterface {
  userLearningWordsLoading: boolean;
  learningWords: learningWordStat[];
}

export const initState: userLearningWordsStoreInterface = {
  userLearningWordsLoading: true,
  learningWords: [],
};

export const userLearningWordsReduceSlice = createSlice({
  name: "userLearningWordsReduce",
  initialState: initState,
  reducers: {
    clearUserLearningWords: (state: userLearningWordsStoreInterface) => {
      state.userLearningWordsLoading = initState.userLearningWordsLoading;
      state.learningWords = initState.learningWords;
    },
    setUserLearningWordsLoadingTrue: (
      state: userLearningWordsStoreInterface,
    ) => {
      state.userLearningWordsLoading = true;
    },
    setUserLearningWordsLoadingFalse: (
      state: userLearningWordsStoreInterface,
    ) => {
      state.userLearningWordsLoading = false;
    },
    setLearningWords: (
      state: userLearningWordsStoreInterface,
      action: { payload: learningWordStat[]; type: string },
    ) => {
      state.learningWords = action.payload;
    },
  },
});

export const {
  clearUserLearningWords,
  setUserLearningWordsLoadingTrue,
  setUserLearningWordsLoadingFalse,
  setLearningWords,
} = userLearningWordsReduceSlice.actions;

export default userLearningWordsReduceSlice.reducer;
