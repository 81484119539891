import axios from "./axios";
import store from "../redux/store";
import { clearToken } from "../redux/app/appReduce";
import { toHome } from "../helpers/redirectHelper";
import { API_LOGOUT } from "../constants/apiRoutConstants";
import { setLogoutLoadingDispatch } from "../redux/app/appDispatcher";

export default function logoutApi() {
  setLogoutLoadingDispatch(true);

  axios
    .get(API_LOGOUT)
    .then(() => {
      successful();
    })
    .catch(() => {
      unsuccessful();
    });
}

const successful = () => {
  store.dispatch(clearToken());
  toHome();
};

const unsuccessful = () => {
  setLogoutLoadingDispatch(false);
  store.dispatch(clearToken());
};
