import { alertAppError } from "../../constants/alertConstants";
import { API_USER_COMPLETE_EDUCATION } from "../../constants/apiRoutConstants";
import { STATUS_OK } from "../../constants/errorCodes";
import { alertWarningDispatch } from "../../redux/alert/alertDispatcher";
import {
  setUserCompleteEducationCompleteDispatch,
  setUserCompleteEducationLoadingFalseDispatch,
  setUserCompleteEducationLoadingTrueDispatch,
} from "../../redux/userCompleteEducation/userCompleteEducationDispatcher";
import axios, { responseInterface } from "../axios";

export default function userCompleteEducationApi() {
  startRequest();

  axios
    .post(API_USER_COMPLETE_EDUCATION)
    .then((res: any) => {
      successful(res);
    })
    .catch(() => {
      unsuccessful();
    });
}

const startRequest = () => {
  setUserCompleteEducationCompleteDispatch(null);
  setUserCompleteEducationLoadingTrueDispatch();
};

const successful = (response: responseInterface<null>) => {
  if (response.statusCode !== STATUS_OK) {
    alertWarningDispatch(alertAppError);
    return;
  }

  setUserCompleteEducationCompleteDispatch(true);
  setUserCompleteEducationLoadingFalseDispatch();
};

const unsuccessful = () => {
  alertWarningDispatch(alertAppError);
};
