import React from "react";
import {
  Box,
  Container,
  Divider,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Link } from "react-router-dom";
import {
  ROUTE_AGREEMENT,
  ROUTE_CONTACTS,
  ROUTE_PRIVACY,
  ROUTE_SERVICES_AND_PAYMENT,
  // ROUTE_SUPPORT,
} from "../../constants/routesConstants";
import { SocialIcon } from "react-social-icons";
import config from "../../config/config";
import { useTheme } from "@mui/material/styles";
import { isApp } from "../../helpers/appHelper";
import ruStore from "../../assets/img/ruStore.webp";

export default function Footer() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  function CoockiesLayout() {
    return (
      <Box color="text.secondary" sx={{ fontSize: 12 }}>
        {
          "Продолжая пользоваться сайтом вы даёте согласие на хранение и использование cookies"
        }
      </Box>
    );
  }

  function Copyright() {
    return (
      <Typography variant="body2" color="text.secondary" sx={{ fontSize: 12 }}>
        {"© Esaye.ru "}
        {new Date().getFullYear()}
      </Typography>
    );
  }

  function InfoLinks() {
    return (
      <Stack
        direction={!matches ? "column" : "row"}
        spacing={!matches ? 0 : 1}
        sx={{ fontSize: 14, color: "text.secondary" }}
      >
        <Link to={ROUTE_AGREEMENT}>{"Пользовательское соглашение"}</Link>
        <Link to={ROUTE_PRIVACY}>{"Политика конфиденциальности"}</Link>
        <Link to={ROUTE_CONTACTS}>{"Контакты и реквизиты"}</Link>
        <Link to={ROUTE_SERVICES_AND_PAYMENT}>
          {"Порядок оплаты и оказания услуг"}
        </Link>
      </Stack>
    );
  }

  function SocialLinks() {
    const socialVkUrl = String(config.socialVkUrl);
    // const socialTelegramUrl = String(config.socialTelegramUrl);

    return (
      <Box sx={{ fontSize: "12px" }}>
        <SocialIcon
          network="vk"
          url={socialVkUrl}
          target="_blank"
          style={{ width: "30px", height: "30px", margin: "0 2px" }}
        />
        {/* <SocialIcon
          network="telegram"
          url={socialTelegramUrl}
          target="_blank"
          style={{ width: "30px", height: "30px", margin: "0 2px" }}
        /> */}
      </Box>
    );
  }

  function Feedback() {
    return (
      <Box sx={{ fontSize: "12px" }}>
        {/* <Link to={ROUTE_SUPPORT}>{"Служба поддержки"}</Link> */}
      </Box>
    );
  }

  function AppLayout() {
    return (
      <Link to={config.ruStoreUrl} target="_blank">
        <img src={ruStore} />
      </Link>
    );
  }

  return (
    <>
      {isApp() ? (
        <Box sx={{ mt: 2 }}></Box>
      ) : (
        <>
          <Container maxWidth="lg" sx={{ mt: 12, mb: 1 }}>
            <Divider color={"#a4c5e5"} />
            {/* <Grid
        container
        sx={{
          mt: 12,
          mb: 1,
          borderTop: "1px solid #a4c5e5",
          p: "0 4",
          display: "flex",
          alignItems: "top",
        }}
      > */}
            {/* <Grid
          xs={!matches ? 12 : "auto"}
          item
          sx={{
            pr: "10px",
            pt: "10px",
          }}
        >
          <InfoLinks />
        </Grid>
        <Grid
          xs={!matches ? 12 : true}
          item
          sx={{
            pr: "10px",
            pt: "10px",
          }}
        >
          <Feedback />
        </Grid> */}
            {/* <Grid
          item
          sx={{
            pt: "10px",
            textAlign: !matches ? "center" : "right",
          }}
          xs={!matches ? 12 : "auto"}
        >
          <SocialLinks />
        </Grid> */}
            {/* </Grid> */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
                mb: 4,
              }}
            >
              <InfoLinks />
            </Box>
            <Box sx={{ textAlign: "center" }}>
              <AppLayout />
            </Box>
            <Box sx={{ textAlign: "center" }}>
              <CoockiesLayout />
            </Box>
            <Box sx={{ textAlign: "center", mb: 4, mt: 1 }}>
              <Copyright />
            </Box>
          </Container>
        </>
      )}
    </>
  );
}
