import React from "react";
import img24 from "../../../assets/img/education/2_4.jpg";
import EducationStepBase from "./EducationStepBase";

export default function EducationStep5() {
  return (
    <EducationStepBase
      title={"Как это работает?"}
      text={"Этой иконкой можно включить/выключить автовоспроизведение слова."}
      image={img24}
    />
  );
}
