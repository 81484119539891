import store from "../store";
import {
  clearUserCompleteEducation,
  setUserCompleteEducationLoadingFalse,
  setUserCompleteEducationLoadingTrue,
  setUserCompleteEducationComplete,
} from "./userCompleteEducationReduce";

export function clearUserCompleteEducationDispatch() {
  store.dispatch(clearUserCompleteEducation());
}

export function setUserCompleteEducationLoadingTrueDispatch() {
  store.dispatch(setUserCompleteEducationLoadingTrue());
}

export function setUserCompleteEducationLoadingFalseDispatch() {
  store.dispatch(setUserCompleteEducationLoadingFalse());
}

export function setUserCompleteEducationCompleteDispatch(
  value: boolean | null,
) {
  store.dispatch(setUserCompleteEducationComplete(value));
}
