import { createSlice } from "@reduxjs/toolkit";

export interface supportSendStoreInterface {
  supportSendLoading: boolean;
  supportSendComplete: boolean | null;
}

export const initState: supportSendStoreInterface = {
  supportSendLoading: false,
  supportSendComplete: null,
};

export const supportSendReduceSlice = createSlice({
  name: "supportSendReduce",
  initialState: initState,
  reducers: {
    clearSupportSend: (state: supportSendStoreInterface) => {
      state.supportSendLoading = initState.supportSendLoading;
      state.supportSendComplete = initState.supportSendComplete;
    },
    setSupportSendLoadingTrue: (state: supportSendStoreInterface) => {
      state.supportSendLoading = true;
    },
    setSupportSendLoadingFalse: (state: supportSendStoreInterface) => {
      state.supportSendLoading = false;
    },
    setSupportSendComplete: (
      state: supportSendStoreInterface,
      action: { payload: boolean | null; type: string },
    ) => {
      state.supportSendComplete = action.payload;
    },
  },
});

export const {
  clearSupportSend,
  setSupportSendLoadingTrue,
  setSupportSendLoadingFalse,
  setSupportSendComplete,
} = supportSendReduceSlice.actions;

export default supportSendReduceSlice.reducer;
