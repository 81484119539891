import axios, { responseInterface } from "../axios";
import { API_LEARN_TEST_CHECK } from "../../constants/apiRoutConstants";
import { STATUS_FORBIDDEN } from "../../constants/errorCodes";
import { alertAppError } from "../../constants/alertConstants";
import { alertWarningDispatch } from "../../redux/alert/alertDispatcher";
import learnCheckResponse from "./learnTestCheckResponse";
import learnTestCheckRequest from "./learnTestCheckRequest";
import {
  setLearnCheckAccessFalseDispatch,
  setLearnCheckAccessTrueDispatch,
  setLearnCheckLoadingFalseDispatch,
  setLearnCheckLoadingTrueDispatch,
  setSuccessDispatch,
  setSuccessNullDispatch,
} from "../../redux/learnCheck/learnCheckDispatcher";

export const learnTestCheckApi = (data: learnTestCheckRequest) => {
  startRequest();

  axios
    .post(API_LEARN_TEST_CHECK, data)
    .then((res: any) => {
      successful(res);
    })
    .catch((res: any) => {
      unsuccessful();
    });
};

const startRequest = () => {
  setLearnCheckLoadingTrueDispatch();
  setSuccessNullDispatch();
};

function successful(response: responseInterface<learnCheckResponse>) {
  if (response.statusCode === STATUS_FORBIDDEN) {
    setLearnCheckAccessFalseDispatch();
    setLearnCheckLoadingFalseDispatch();
    return;
  }

  setLearnCheckAccessTrueDispatch();
  setSuccessDispatch(response.data.success);

  setLearnCheckLoadingFalseDispatch();
}

function unsuccessful() {
  alertWarningDispatch(alertAppError);
}
