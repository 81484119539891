import { createSlice } from "@reduxjs/toolkit";

export interface authResetPasswordStoreInterface {
  authResetPasswordComplete: boolean | null;
  authResetPasswordLoading: boolean;
}

export const initState: authResetPasswordStoreInterface = {
  authResetPasswordComplete: null,
  authResetPasswordLoading: false,
};

const authResetPasswordReduceSlice = createSlice({
  name: "authResetPasswordReduce",
  initialState: initState,
  reducers: {
    clearAuthResetPassword: (state: authResetPasswordStoreInterface) => {
      state.authResetPasswordComplete = initState.authResetPasswordComplete;
      state.authResetPasswordLoading = initState.authResetPasswordLoading;
    },
    setAuthResetPasswordComplete: (
      state: authResetPasswordStoreInterface,
      action: { payload: boolean | null; type: string },
    ) => {
      state.authResetPasswordComplete = action.payload;
    },
    setAuthResetPasswordLoading: (
      state: authResetPasswordStoreInterface,
      action: { payload: boolean; type: string },
    ) => {
      state.authResetPasswordLoading = action.payload;
    },
  },
});

export const {
  clearAuthResetPassword,
  setAuthResetPasswordComplete,
  setAuthResetPasswordLoading,
} = authResetPasswordReduceSlice.actions;

export default authResetPasswordReduceSlice.reducer;
