import axios, { responseInterface } from "./axios";
import store from "../redux/store";
import { setToken } from "../redux/app/appReduce";
import {
  setTokenCompleteDispatch,
  setTokenLoadingFalseDispatch,
  setTokenLoadingTrueDispatch,
} from "../redux/app/appDispatcher";

const AUTH_SOCIAL = "/auth/social";

export interface authSocialDataInterface {
  token: string;
}

export type authSocialSuccessfulData = {
  token: string;
};

export const authSocialData = (token: string) => {
  return {
    token: token,
  };
};

export const authSocial = (data: authSocialDataInterface) => {
  setTokenLoadingTrueDispatch();
  setTokenCompleteDispatch(null);

  axios
    .post(AUTH_SOCIAL, data)
    .then((res: any) => {
      successful(res);
    })
    .catch((res: responseInterface) => {
      setTokenLoadingFalseDispatch();
      setTokenCompleteDispatch(false);
    });
};

const successful = (response: responseInterface<authSocialSuccessfulData>) => {
  if (response?.data?.token !== undefined) {
    setTokenCompleteDispatch(null);
    store.dispatch(setToken(response?.data?.token));
  }

  setTokenLoadingFalseDispatch();
};
