import { storeInterface } from "../store";

const learnKnowWordLoading = (state: storeInterface) =>
  state.learnKnowWord.learnKnowWordLoading;
const learnKnowWordComplete = (state: storeInterface) =>
  state.learnKnowWord.learnKnowWordComplete;

export default {
  learnKnowWordLoading,
  learnKnowWordComplete,
};
