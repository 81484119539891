import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import EmailInput from "../../components/forms/inputs/EmailInput";
import PasswordInput from "../../components/forms/inputs/PasswordInput";
import { Link, useNavigate } from "react-router-dom";
import VkAuthButton from "../../components/socialAuth/vkAuth/VkAuthButton";
import {
  ROUTE_AGREEMENT,
  ROUTE_PRIVACY,
  ROUTE_SIGN_IN,
} from "../../constants/routesConstants";
import formErrorsSelectors from "../../redux/formErrors/formErrorsSelectors";
import { FormErrorsText } from "../../components/forms/FormErrorsText";
import { clearFormError } from "../../services/formErrors/formErrorsService";
import userDataSelectors from "../../redux/userData/userDataSelectors";
import { LoadingButton } from "@mui/lab";
// import NameInput from "../../components/forms/inputs/NameInput";
import { Checkbox, Divider } from "@mui/material";
import UserAgreementCheckbox from "../../components/forms/inputs/UserAgreementCheckbox";
import PrivacyPolicyCheckbox from "../../components/forms/inputs/PrivacyPolicyCheckbox";
import { signUpHandle } from "../../services/signUp/signUpService";

export default function SignUpContainer() {
  const navigate = useNavigate();
  // const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const [userAgreementCheckbox, setUserAgreementCheckbox] = useState(false);
  const [privacyPolicyCheckbox, setPrivacyPolicyCheckbox] = useState(false);
  const [emailSubscribe, setEmailSubscribe] = useState(false);

  const mainErrors = useSelector(formErrorsSelectors.formErrors).main;
  const authLoading = useSelector(userDataSelectors.authLoading);

  useEffect(() => {
    clearFormError();

    return () => {
      clearFormError();
    };
  }, []);

  // function changeHandle(name: string) {
  //   setName(name);
  // }

  function changeEmailHandle(email: string) {
    setEmail(email);
  }

  function changePasswordHandle(password: string) {
    setPassword(password);
    setPasswordRepeat(password);
  }

  function userAgreementCheckboxHandle() {
    setUserAgreementCheckbox(!userAgreementCheckbox);
  }

  function privacyPolicyCheckboxHandle() {
    setPrivacyPolicyCheckbox(!privacyPolicyCheckbox);
  }

  function emailSubscribeCheckboxHandle() {
    setEmailSubscribe(!emailSubscribe);
  }

  function handle() {
    signUpHandle(
      "YourName",
      email,
      password,
      passwordRepeat,
      userAgreementCheckbox,
      privacyPolicyCheckbox,
      emailSubscribe,
    );
  }

  function toSingIn() {
    navigate(ROUTE_SIGN_IN);
  }

  return (
    <>
      <Box
        sx={{
          marginTop: "50px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5" sx={{ mb: "25px" }}>
          {"Регистрация"}
        </Typography>
        <Box sx={{ mt: "5px" }}>
          {/* <Divider
            textAlign="left"
            sx={{ mt: "25px", mb: "25px", fontSize: "18px" }}
          >
            {"Имя"}
          </Divider>
          <Grid container>
            <Grid
              xs={4}
              item
              sx={{
                placeSelf: "top",
                pt: "15px",
                pr: "10px",
                textAlign: "right",
                fontWeight: "bold",
                fontSize: "14px",
                height: "25px",
              }}
            >
              {"Имя:"}
            </Grid>
            <Grid xs={8} item>
              <NameInput
                name={name}
                loading={authLoading}
                valueChangeHandle={changeHandle}
              />
            </Grid>
          </Grid> */}
          <Divider
            textAlign="left"
            sx={{ mt: "25px", mb: "25px", fontSize: "18px" }}
          >
            {"Данные для входа"}
          </Divider>
          <Grid container>
            <Grid
              xs={4}
              item
              sx={{
                placeSelf: "top",
                pt: "15px",
                pr: "10px",
                textAlign: "right",
                fontWeight: "bold",
                fontSize: "14px",
              }}
            >
              {"Email:"}
            </Grid>
            <Grid xs={8} item>
              <EmailInput
                email={email}
                loading={authLoading}
                valueChangeHandle={changeEmailHandle}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid
              xs={4}
              item
              sx={{
                placeSelf: "top",
                pt: "15px",
                pr: "10px",
                textAlign: "right",
                fontWeight: "bold",
                fontSize: "14px",
              }}
            >
              {"Пароль:"}
            </Grid>
            <Grid xs={8} item>
              <PasswordInput
                password={password}
                loading={authLoading}
                valueChangeHandle={changePasswordHandle}
              />
            </Grid>
          </Grid>
          <Divider
            textAlign="left"
            sx={{ mt: "25px", mb: "25px", fontSize: "18px" }}
          >
            {"Дополнительные соглашения"}
          </Divider>
          <Grid container sx={{}}>
            <Grid
              xs={2}
              item
              sx={{
                placeSelf: "center",
                pr: "10px",
              }}
            >
              <UserAgreementCheckbox
                checked={userAgreementCheckbox}
                loading={authLoading}
                valueChangeHandle={userAgreementCheckboxHandle}
              />
            </Grid>
            <Grid
              xs={10}
              item
              sx={{
                fontSize: "12px",
                placeSelf: "center",
              }}
            >
              {
                "Соглашаюсь на обработку моих персональных данных в соответствии с "
              }
              <Link to={ROUTE_PRIVACY}>{"ПОЛИТИКОЙ КОНФИДЕНЦИАЛЬНОСТИ"}</Link>
            </Grid>
          </Grid>
          <Grid container>
            <Grid
              xs={2}
              item
              sx={{
                placeSelf: "center",
                pr: "10px",
              }}
            >
              <PrivacyPolicyCheckbox
                checked={privacyPolicyCheckbox}
                loading={authLoading}
                valueChangeHandle={privacyPolicyCheckboxHandle}
              />
            </Grid>
            <Grid
              xs={10}
              item
              sx={{
                fontSize: "12px",
                placeSelf: "center",
              }}
            >
              {"Принимаю условия "}
              <Link to={ROUTE_AGREEMENT}>{"ПОЛЬЗОВАТЕЛЬСКОГО СОГЛАШЕНИЯ"}</Link>
            </Grid>
          </Grid>
          <Grid container>
            <Grid
              xs={2}
              item
              sx={{
                placeSelf: "center",
                pr: "10px",
              }}
            >
              <Checkbox
                disabled={authLoading}
                checked={emailSubscribe}
                onChange={emailSubscribeCheckboxHandle}
                name={"privacyPolicyCheckbox"}
              />
            </Grid>
            <Grid
              xs={10}
              item
              sx={{
                fontSize: "12px",
                placeSelf: "center",
              }}
            >
              {"Хочу получать сообщения со скидками и акциями"}
            </Grid>
          </Grid>
          <FormErrorsText propertyValues={mainErrors} />
          <Box sx={{ textAlign: "center" }}>
            <LoadingButton
              loading={authLoading}
              variant="contained"
              sx={{ mt: "15px", mb: "10px" }}
              onClick={handle}
            >
              {"Регистрация"}
            </LoadingButton>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          m: "10px 0",
          textAlign: "center",
        }}
      >
        {"или"}
      </Box>
      <Box>
        <VkAuthButton />
      </Box>
      <Box
        sx={{
          mt: "20px",
          textAlign: "right",
        }}
      >
        <Button onClick={toSingIn}>{"Выполнить вход"}</Button>
      </Box>
    </>
  );
}
