import React, { useEffect } from "react";
import { isProd } from "../../helpers/appHelper";

export default function VkPixelLearn() {
  useEffect(() => {
    if (isProd()) {
      const t = document.createElement("script");
      t.type = "text/javascript";
      t.async = !0;
      t.src = "https://vk.com/js/api/openapi.js?173";
      t.onload = function () {
        const VK = window.VK;
        if (VK && VK.Retargeting) {
          VK.Retargeting.Init("VK-RTRG-1903634-34FSJ");
          VK.Retargeting.Hit();
        }
      };
      document.head.appendChild(t);
    }
    1;
  }, []);
  return <></>;
}
