export const COLOR_WORD_TYPE_ONE = "rgb(70, 189, 0)";
export const COLOR_WORD_TYPE_TWO = "rgb(255, 153, 0)";
export const COLOR_WORD_TYPE_THREE = "rgb(82, 0, 73)";

export const BG_COLOR_WORD_TYPE_ONE = "rgb(255, 255, 255)";
export const BG_COLOR_WORD_TYPE_TWO = "rgba(255, 153, 0, 0.04)";
export const BG_COLOR_WORD_TYPE_THREE = "rgba(82, 0, 73, 0.04)";

export const BORDER_COLOR_WORD_TYPE_ONE = "rgba(0, 227, 28, 0.9)";
export const BORDER_COLOR_WORD_TYPE_TWO = "rgba(255, 153, 0, 0.9)";
export const BORDER_COLOR_WORD_TYPE_THREE = "rgba(82, 0, 73, 0.9)";
