import React from "react";
import img215 from "../../../assets/img/education/2_15.jpg";
import EducationStepBase from "./EducationStepBase";

export default function EducationStep16() {
  return (
    <EducationStepBase
      title={"Мои словари"}
      text={
        'Заполнять слова можно вручную. Для этого в соответствующее поле ввeдите нужное слово и выберете его из предоставленных вариантов. Если ни один из вариантов не подходит, то слово можно добавить, нажав на "Нет нужного? Добавить"'
      }
      image={img215}
    />
  );
}
