import { Box } from "@mui/material";
import React from "react";
import fanfare from "../../../assets/img/fanfare.webp";

export default function EducationStep1() {
  return (
    <>
      <Box>
        <Box sx={{ textAlign: "center" }}>
          <img src={fanfare} />
        </Box>
        <Box sx={{ fontSize: 34, mb: 6, textAlign: "center" }}>
          {"Добро пожаловать!"}
        </Box>
        <Box sx={{ fontSize: 18 }}>
          {"Предлагаю пройти краткую экскурсию по функционалу."}
        </Box>
        <Box sx={{ fontSize: 18 }}>{"Согласны?"}</Box>
      </Box>
    </>
  );
}
