import { storeInterface } from "../store";

const userLearningWordsLoading = (state: storeInterface) =>
  state.userLearningWords.userLearningWordsLoading;
const learningWords = (state: storeInterface) =>
  state.userLearningWords.learningWords;

export default {
  userLearningWordsLoading,
  learningWords,
};
