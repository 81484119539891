import React from "react";
import img22 from "../../../assets/img/education/2_2.jpg";
import EducationStepBase from "./EducationStepBase";

export default function EducationStep3() {
  return (
    <EducationStepBase
      title={"Как это работает?"}
      text={"Неправильно выбранное значение будет выделено красным цветом."}
      image={img22}
    />
  );
}
