import { createSlice } from "@reduxjs/toolkit";

export interface userCompleteEducationStoreInterface {
  userCompleteEducationLoading: boolean;
  userCompleteEducationComplete: boolean | null;
}

export const initState: userCompleteEducationStoreInterface = {
  userCompleteEducationLoading: false,
  userCompleteEducationComplete: null,
};

export const userCompleteEducationReduceSlice = createSlice({
  name: "userCompleteEducationReduce",
  initialState: initState,
  reducers: {
    clearUserCompleteEducation: (
      state: userCompleteEducationStoreInterface,
    ) => {
      state.userCompleteEducationLoading =
        initState.userCompleteEducationLoading;
      state.userCompleteEducationComplete =
        initState.userCompleteEducationComplete;
    },
    setUserCompleteEducationLoadingTrue: (
      state: userCompleteEducationStoreInterface,
    ) => {
      state.userCompleteEducationLoading = true;
    },
    setUserCompleteEducationLoadingFalse: (
      state: userCompleteEducationStoreInterface,
    ) => {
      state.userCompleteEducationLoading = false;
    },
    setUserCompleteEducationComplete: (
      state: userCompleteEducationStoreInterface,
      action: { payload: boolean | null; type: string },
    ) => {
      state.userCompleteEducationComplete = action.payload;
    },
  },
});

export const {
  clearUserCompleteEducation,
  setUserCompleteEducationLoadingTrue,
  setUserCompleteEducationLoadingFalse,
  setUserCompleteEducationComplete,
} = userCompleteEducationReduceSlice.actions;

export default userCompleteEducationReduceSlice.reducer;
