import React from "react";
import img213 from "../../../assets/img/education/2_13.jpg";
import EducationStepBase from "./EducationStepBase";

export default function EducationStep14() {
  return (
    <EducationStepBase
      title={"Мои словари"}
      text={
        'Необходимо ввести название словаря и нажать на кнопку "Создать словарь"'
      }
      image={img213}
    />
  );
}
