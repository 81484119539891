import React from "react";
import EducationStepBase from "./EducationStepBase";

export default function EducationStep21() {
  return (
    <EducationStepBase
      title={"Прступить к изучению"}
      text={
        "Поздравляю! Вы прошли краткое обучение. Теперь можете приступить к изучению слов."
      }
    />
  );
}
