import React from "react";
import img210 from "../../../assets/img/education/2_10.jpg";
import EducationStepBase from "./EducationStepBase";

export default function EducationStep11() {
  return (
    <EducationStepBase
      title={"Мои словари"}
      text={
        'Словарь - набор слов. В разделе "Мои словари" можно составлять свои словари. Так вы можете изучать только те слова, которые сами хотите. Нажимая на кнопку "Продолжить изучение", вы перейдете к изучению выбранного словаря.'
      }
      image={img210}
    />
  );
}
