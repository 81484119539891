import { createSlice } from "@reduxjs/toolkit";
import chooseValueWord from "../../models/chooseValueWord";

export interface learnNextStoreInterface {
  learnNext: chooseValueWord | null;
  learnNextLoading: boolean;
  learnNextAccess: boolean;
}

const initStore: learnNextStoreInterface = {
  learnNext: null,
  learnNextLoading: true,
  learnNextAccess: true,
};

export const learnNextReduceSlice = createSlice({
  name: "learnNextReduce",
  initialState: initStore,
  reducers: {
    clearLearnNext: (state: learnNextStoreInterface) => {
      state.learnNext = initStore.learnNext;
      state.learnNextLoading = initStore.learnNextLoading;
      state.learnNextAccess = initStore.learnNextAccess;
    },
    setLearnNextAccessTrue: (state: learnNextStoreInterface) => {
      state.learnNextAccess = true;
    },
    setLearnNextAccessFalse: (state: learnNextStoreInterface) => {
      state.learnNextAccess = false;
    },
    setLearnNext: (
      state: learnNextStoreInterface,
      actions: { payload: chooseValueWord | null; type: string },
    ) => {
      state.learnNext = actions.payload;
    },
    setLearnNextLoadingTrue: (state: learnNextStoreInterface) => {
      state.learnNextLoading = true;
    },
    setLearnNextLoadingFalse: (state: learnNextStoreInterface) => {
      state.learnNextLoading = false;
    },
  },
});

export const {
  clearLearnNext,
  setLearnNextAccessTrue,
  setLearnNextAccessFalse,
  setLearnNext,
  setLearnNextLoadingTrue,
  setLearnNextLoadingFalse,
} = learnNextReduceSlice.actions;

export default learnNextReduceSlice.reducer;
