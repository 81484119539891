import { storeInterface } from "../store";

const authResetPasswordComplete = (state: storeInterface) =>
  state.authResetPassword.authResetPasswordComplete;
const authResetPasswordLoading = (state: storeInterface) =>
  state.authResetPassword.authResetPasswordLoading;

export default {
  authResetPasswordComplete,
  authResetPasswordLoading,
};
