export const appTitle = "Easye";
export const indexPageTitle = "Повышаем словарный запас";
export const TITLE_PAGE_LEARN = "Изучение слов";
export const TITLE_PAGE_LEARN_GROUP_PERSONAL = "Изучение словаря";
export const TITLE_PAGE_LEARN_TEST = "Тестирование";
export const TITLE_PAGE_LEARN_EXAM = "Экзамен";
export const TITLE_PAGE_GROUP_PAY = "Покупка словаря";
export const TITLE_PAGE_GROUPS = "Найти словарь";
export const TITLE_PAGE_GROUPS_PERSONAL = "Мои словари";
export const TITLE_PAGE_GROUPS_AVAILABLE = "Доступные словари";
export const TITLE_PAGE_PAY_PERSONAL_WORD = "Купить пространство";
export const TITLE_PAGE_GROUP_ENVIRONMENT = "Сборник";
export const TITLE_PAGE_WORKOUT = "Тренировка";
export const TITLE_PAGE_SETTINGS = "Настройки";
export const TITLE_PAGE_TEXT = "Работа с текстом";
export const TITLE_PAGE_BALANCE = "Баланс";
export const TITLE_PAGE_GROUP_ADD = "Добавить словарь";
export const TITLE_PAGE_GROUP_PERSONAL_EDIT = "Редактирование словаря";
export const TITLE_PAGE_GROUP_EDIT = "Редактирование словаря";
export const TITLE_PAGE_GROUP_TESTS = "Тесты словаря";
export const TITLE_PAGE_GROUP_TEST_ADD = "Добавить тест";
export const TITLE_PAGE_GROUP_EDIT_TEST = "Редактирование теста";
export const TITLE_PAGE_GROUP_ADD_EXAM = "Добавить экзамен";
export const TITLE_PAGE_GROUP_EDIT_EXAM = "Редактирование экзамена";
export const TITLE_PAGE_TEST = "Тестирование";
export const TITLE_PAGE_EXAM = "Экзамен";
export const TITLE_PAGE_PROFILE = "Профиль";
export const TITLE_PAGE_FILL_USER_DATA = "Недостающие данные";
export const TITLE_PAGE_PRIVACY =
  "Политика в отношении обработки персональных данных";
export const TITLE_CONFIRM_EMAIL = "Подтверждение E-mail адреса";
export const TITLE_PAGE_AGREEMENT = "Пользовательское соглашение";
export const TITLE_PAGE_RESET_PASSWORD = "Восстановление доступа";
export const TITLE_PAGE_SUPPORT = "Служба поддержки";
export const TITLE_PAGE_PAY = "Оплата подписки";
export const TITLE_PAGE_CONTACTS = "Контакты и реквизиты";
export const TITLE_PAGE_SERVICES_AND_PAYMENT =
  "Порядок оплаты и оказания услуг";
export const TITLE_PAGE_USER_UNSUBSCRIBE = "Отписаться от рассылки";
export const TITLE_PAGE_LEARNING_WORDS = "Изученные слова";
