import { alertAppError } from "../../constants/alertConstants";
import { API_SUPPORT_SEND } from "../../constants/apiRoutConstants";
import { STATUS_OK } from "../../constants/errorCodes";
import { alertWarningDispatch } from "../../redux/alert/alertDispatcher";
import {
  setSupportSendCompleteDispatch,
  setSupportSendLoadingFalseDispatch,
  setSupportSendLoadingTrueDispatch,
} from "../../redux/supportSend/supportSendDispatcher";
import axios, { responseInterface } from "../axios";
import supportSendRequest from "./supportSendRequest";

export default function supportSendApi(data: supportSendRequest) {
  startRequest();

  axios
    .post(API_SUPPORT_SEND, data)
    .then((res: any) => {
      successful(res);
    })
    .catch(() => {
      unsuccessful();
    });
}

const startRequest = () => {
  setSupportSendCompleteDispatch(null);
  setSupportSendLoadingTrueDispatch();
};

const successful = (response: responseInterface<null>) => {
  if (response.statusCode !== STATUS_OK) {
    alertWarningDispatch(alertAppError);
    return;
  }

  setSupportSendCompleteDispatch(true);
  setSupportSendLoadingFalseDispatch();
};

const unsuccessful = () => {
  alertWarningDispatch(alertAppError);
};
