import supportSendRequest from "./supportSendRequest";

export default function supportSendData(
  text: string,
  email: string | null,
): supportSendRequest {
  return {
    text: text,
    email: email,
  };
}
