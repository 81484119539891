export const API_USER_GENERATE_PAY = "/user/generatePay";
export const API_LOGOUT = "/logout";
export const API_APP_DATA = "/app/data";
export const API_USER_DATA = "/user/data";
export const API_USER_SUBSCRIBE_DATA = "/user/subscribeData";
export const API_USER_TOKEN = "/user/token";
export const API_UPDATE_NAME = "/user/update/name";
export const API_CREATE_EMAIL_AUTH = "/user/createEmailAuth";
export const API_UPDATE_EMAIL = "/user/update/email";
export const API_USER_CONFIRM_EMAIL = "/user/confirmEmail";
export const API_USER_UNSUBSCRIBE = "/user/unsubscribe";
export const API_UPDATE_PASSWORD = "/user/update/password";
export const API_SOCIAL_REMOVE = "/user/social/remove";
export const API_USER_LEARNING_WORDS = "/user/learningWords";
export const API_USER_SEND_EMAIL_CONFIRM_CODE = "/user/sendEmailConfirmCode";
export const API_USER_COMPLETE_EDUCATION = "/user/completeEducation";
export const API_AUTH_EMAIL = "/auth/email";
export const API_AUTH_CREATE = "/auth/create";
export const API_AUTH_RESET_PASSWORD = "/auth/resetPassword";
export const API_LEARN_NEXT_WORD = "/learn/nextWord";
export const API_LEARN_NEW_WORD = "/learn/newWord";
export const API_LEARN_PROCESS = "/learn/process";
export const API_LEARN_TEST_LIST = "/learn/testList";
export const API_LEARN_TEST = "/learn/test";
export const API_LEARN_TEST_PROCESS = "/learn/testProcess";
export const API_LEARN_EXAM = "/learn/exam";
export const API_LEARN_EXAM_PROCESS = "/learn/examProcess";
export const API_LEARN_DATA = "/learn/data";
export const API_LEARN_NEXT = "/learn/next";
export const API_LEARN_CHECK = "/learn/check";
export const API_LEARN_TEST_DATA = "/learn/test/data";
export const API_LEARN_TEST_NEXT = "/learn/test/next";
export const API_LEARN_TEST_CHECK = "/learn/test/check";
export const API_LEARN_GROUP_PERSONAL_DATA = "/learn/groupPersonal/data";
export const API_LEARN_GROUP_PERSONAL_CHECK = "/learn/groupPersonal/check";
export const API_LEARN_GROUP_PERSONAL_NEXT = "/learn/groupPersonal/next";
export const API_LEARN_KNOW_WORD = "/learn/knowWord";
export const API_GROUP_DATA = "/group/data";
export const API_GROUP_DATA_PAY = "/group/dataPay";
export const API_GROUP_DATA_LEARN = "/group/dataLearn";
export const API_GROUP_EDIT_DATA = "/group/editData";
export const API_WORD_FIND = "/word/find";
export const API_WORD_CREATE = "/word/create";
export const API_WORDS_FROM_TEXT = "/words/fromText";
export const API_GROUP_PARTITIONS = "/group/partitions";
export const API_GROUP_ENVIRONMENT = "/group/environment";
export const API_GROUP_ENVIRONMENTS = "/group/environments";
export const API_GROUP_NEW_ENVIRONMENT = "/group/newEnvironment";
export const API_GROUP_CREATE = "/group/create";
export const API_GROUP_PERSONAL_LIST = "/groupPersonal/list";
export const API_GROUP_AVAILABLE_LIST = "/group/availableList";
export const API_GROUP_PERSONAL_CREATE = "/groupPersonal/create";
export const API_GROUP_PERSONAL_DELETE = "/groupPersonal/delete";
export const API_GROUP_PERSONAL_UPDATE = "/groupPersonal/update";
export const API_GROUP_PERSONAL_DATA = "/groupPersonal/data";
export const API_GROUP_UPDATE = "/group/update";
export const API_GROUP_INFO = "/group/info";
export const API_GROUP_ADD_TEST = "/group/addTest";
export const API_GROUP_TEST_LIST = "/group/testList";
export const API_GROUP_ADD_EXAM = "/group/addExam";
export const API_GROUP_REMOVE_EXAM = "/group/removeExam";
export const API_GROUP_REMOVE_TEST = "/group/removeTest";
export const API_LEARN_START = "/learn/start";
export const API_SUPPORT_MESSAGES = "/support/messages";
export const API_SUPPORT_SEND_MESSAGE = "/support/sendMessage";
export const API_WORKOUT_NEXT_WORD = "/workout/nextWord";
export const API_WORKOUT_PROCESS = "/workout/process";
export const API_SUPPORT_SEND = "/support/send";
