import {
  BG_COLOR_WORD_TYPE_ONE,
  BG_COLOR_WORD_TYPE_THREE,
  BG_COLOR_WORD_TYPE_TWO,
  COLOR_WORD_TYPE_ONE,
  COLOR_WORD_TYPE_THREE,
  COLOR_WORD_TYPE_TWO,
} from "../constants/appConstants";
import purpleFlame from "../assets/img/PurpleFlame.webp";

export function colorByWordType(type: number) {
  switch (type) {
    case 1:
      return COLOR_WORD_TYPE_TWO;
    case 2:
      return COLOR_WORD_TYPE_THREE;
    default:
      return COLOR_WORD_TYPE_ONE;
  }
}

export function bgColorByWordType(type: number) {
  switch (type) {
    case 1:
      return BG_COLOR_WORD_TYPE_TWO;
    case 2:
      return BG_COLOR_WORD_TYPE_THREE;
    default:
      return BG_COLOR_WORD_TYPE_ONE;
  }
}

export function titleByWordType(type: number) {
  switch (type) {
    case 1:
      return "Редкое";
    case 2:
      return "Эпическое";
    default:
      return "Обычное";
  }
}

export function imageByWordType(type: number) {
  switch (type) {
    case 1:
      return "";
    case 2:
      return purpleFlame;
    default:
      return "";
  }
}
