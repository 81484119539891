import learnTestCheckRequest from "./learnTestCheckRequest";

export default function learnTestCheckData(
  learningWordId: number,
  learningWordTranslateId: number,
): learnTestCheckRequest {
  return {
    learningWordId: learningWordId,
    learningWordTranslateId: learningWordTranslateId,
  };
}
