import { alertAppError } from "../../constants/alertConstants";
import { API_LEARN_KNOW_WORD } from "../../constants/apiRoutConstants";
import { STATUS_OK } from "../../constants/errorCodes";
import { alertWarningDispatch } from "../../redux/alert/alertDispatcher";
import {
  setLearnKnowWordCompleteDispatch,
  setLearnKnowWordLoadingFalseDispatch,
  setLearnKnowWordLoadingTrueDispatch,
} from "../../redux/learnKnowWord/learnKnowWordDispatcher";
import axios, { responseInterface } from "../axios";
import learnKnowWordRequest from "./learnKnowWordRequest";

export default function learnKnowWordApi(data: learnKnowWordRequest) {
  startRequest();

  axios
    .post(API_LEARN_KNOW_WORD, data)
    .then((res: any) => {
      successful(res);
    })
    .catch(() => {
      unsuccessful();
    });
}

const startRequest = () => {
  setLearnKnowWordCompleteDispatch(null);
  setLearnKnowWordLoadingTrueDispatch();
};

const successful = (response: responseInterface<null>) => {
  if (response.statusCode !== STATUS_OK) {
    alertWarningDispatch(alertAppError);
    setLearnKnowWordLoadingFalseDispatch();
    return;
  }
  setLearnKnowWordCompleteDispatch(true);
  setLearnKnowWordLoadingFalseDispatch();
};

const unsuccessful = () => {
  alertWarningDispatch(alertAppError);
};
