import { storeInterface } from "../store";

const supportSendLoading = (state: storeInterface) =>
  state.supportSend.supportSendLoading;
const supportSendComplete = (state: storeInterface) =>
  state.supportSend.supportSendComplete;

export default {
  supportSendLoading,
  supportSendComplete,
};
